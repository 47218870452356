/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import { Form, Input, InputNumber, Modal, Select, Tooltip, Upload } from "antd";
import React from "react";
import { useState } from "react";
import compressImage from "../../utils/CompressImage";
import { PlusOutlined } from "@ant-design/icons";

const ModalAddCreditor = (props) => {
  const [form] = Form.useForm();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  //menyimpan default file list
  const [defaultDasarTagihan, setDefaultDasarTagihan] = useState([]);
  // menyimpan list file gambar
  const [listRekap, setListRekap] = useState([]);
  const [listDasarTagihan, setListDasarTagihan] = useState([]);
  const [listBuktiPembayaran, setListBuktiPembayaran] = useState([]);
  // menyimpan gambar
  const [rekap, setRekap] = useState(null);
  const [dasarTagihan, setDasarTagihan] = useState(null);
  const [buktiPembayaran, setBuktiPembayaran] = useState(null);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //properti gambar
  const propsRekap = {
    onRemove: (file) => {
      const index = listRekap.indexOf(file);
      const newFileList = listRekap.slice();
      newFileList.splice(index, 1);
      setListRekap(newFileList);
    },
    beforeUpload: async (file) => {
      setListRekap([...listRekap, await compressImage(file)]);
      setRekap(await compressImage(file));

      return false;
    },
    listRekap,
  };

  const propsDasarTagihan = {
    onRemove: (file) => {
      const index = listDasarTagihan.indexOf(file);
      const newFileList = listDasarTagihan.slice();
      newFileList.splice(index, 1);
      setListDasarTagihan(newFileList);
    },
    beforeUpload: async (file) => {
      setListDasarTagihan([...listDasarTagihan, await compressImage(file)]);
      setDasarTagihan(await compressImage(file));

      return false;
    },
    listDasarTagihan,
  };

  const propsBuktiPembayaran = {
    onRemove: (file) => {
      const index = listBuktiPembayaran.indexOf(file);
      const newFileList = listBuktiPembayaran.slice();
      newFileList.splice(index, 1);
      setListBuktiPembayaran(newFileList);
    },
    beforeUpload: async (file) => {
      setListBuktiPembayaran([
        ...listBuktiPembayaran,
        await compressImage(file),
      ]);
      setRekap(await compressImage(file));

      return false;
    },
    listBuktiPembayaran,
  };

  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const parser = (value) => value.replace(/\Rp.\s?|(,*)/g, "");
  //on change gambar
  const onChangedRekap = async (info) => {
    setRekap(await compressImage(info.file));
  };
  const onChangedDasarTagihan = async (info) => {
    console.log(defaultDasarTagihan);
    if (info.file.status === "removed") {
      setDefaultDasarTagihan([]);
    } else {
      setDasarTagihan(await compressImage(info.file));
    }
  };
  const onChangedBuktiPembayaran = async (info) => {
    setBuktiPembayaran(await compressImage(info.file));
  };
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  function closeModal() {
    form.resetFields();
    // setDefaultDasarTagihan([]);
    setListRekap([]);
    setListDasarTagihan([]);
    setListBuktiPembayaran([]);
    // props.offModalEdit();
  }
  return (
    <Modal
      open={props.modalAdd}
      onCancel={() => props.setModalAdd(false)}
      footer={false}
    >
      <Form form={form} layout="vertical" name="nest-messages">
        <div className="max-w-lg m-auto space-y-5">
          <Form.Item
            label={
              <Tooltip
                className="dark:text-gray-200"
                title="Checklist jika menggunakan kuasa hukum"
              >
                Sifat Tagihan
              </Tooltip>
            }
            name="billNature"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Select.Option value="konkuren">Kreditor Konkuren</Select.Option>
              <Select.Option value="preferen">Kreditor Preferen</Select.Option>
              <Select.Option value="separatis">
                Kreditor Separatis
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="No Tagihan"
            name="invoiceNumber"
            rules={[
              {
                required: true,
                message: "No tagihan wajib di isi",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="principalAmount" label="Tagihan Pokok">
            <InputNumber
              formatter={formatter}
              parser={parser}
              className="w-full"
            />
          </Form.Item>
          <Form.Item label="Bunga" name="interest">
            <InputNumber
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>
          <Form.Item name="amercement" label="Denda">
            <InputNumber
              formatter={formatter}
              parser={parser}
              className="w-full"
            />
          </Form.Item>
          <div className="flex justify-between flex-wrap">
            <Form.Item label="Rekapitulasi Tagihan" name={"billRecapitulation"}>
              <Upload
                accept="image/*"
                {...propsRekap}
                onChange={onChangedRekap}
                listType="picture-card"
                onPreview={handlePreview}
              >
                {listRekap.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Select File
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
            <Form.Item label="Dasar Tagihan" name={"fundamentalBill"}>
              <Upload
                defaultFileList={defaultDasarTagihan}
                accept="image/*"
                {...propsDasarTagihan}
                onChange={onChangedDasarTagihan}
                listType="picture-card"
                onPreview={handlePreview}
              >
                {defaultDasarTagihan.length === 0 ? (
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Select File
                    </div>
                  </div>
                ) : null}
              </Upload>
            </Form.Item>
            <Form.Item label="Bukti Pembayaran" name={"proofOfPayment"}>
              <Upload
                accept="image/*"
                {...propsBuktiPembayaran}
                onChange={onChangedBuktiPembayaran}
                listType="picture-card"
                onPreview={handlePreview}
              >
                {listBuktiPembayaran.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Select File
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </div>
        </div>
        <div className="flex justify-end items-center p-6 pb-0 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
          <Form.Item>
            <button
              data-modal-toggle="defaultModal"
              type="submit"
              className="text-white bg-primary dark:bg-gray-700 dark:border-white dark:border hover:drop-shadow-xl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  dark:hover:bg-gray-600 dark:hover:text-gray-200"
            >
              Save Data Tagihan
            </button>
          </Form.Item>
          <Form.Item>
            <button
              onClick={closeModal}
              data-modal-toggle="defaultModal"
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-white dark:text-gray-700 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Cancel
            </button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddCreditor;

/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, message, Popconfirm, Popover, Steps, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import FormPersonalData from "./FormPersonalData";
import FormTagihan from "./FormTagihan";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { DatabaseOutlined } from "@ant-design/icons";
import ReviewRegister from "./ReviewRegister";
import ModalEditTagihanRegister from "./ModalEditTagihanRegister";
import compressImage from "../../utils/CompressImage";
import RegisterService from "../../app/Services/RegisterService";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ToastNoify from "../../utils/ToastNotify";
import Cookies from "js-cookie";
import CONFIG from "../../app/Config/CONFIG";
const Register = () => {
  //route tersedia
  const navigate = useNavigate();
  const { perkara } = useParams();
  const [validRoute, setValidRoute] = useState(true);
  const [listOfLawsuit, setListOfLawsuit] = useState([]);
  const [registered, setRegistered] = useState(false);

  // cek routenya tersedia atau tidak
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${CONFIG}/lawsuit/active`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setListOfLawsuit(response.data.data.lawsuits);
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    const dataCookie = Cookies.get("dataRegister");

    if (dataCookie !== undefined) {
      setRegistered(true);
    }

    const isValidPerkara = listOfLawsuit.map((value) => {
      return value.lawsuitId === perkara;
    });
    if (!isValidPerkara) {
      // Mengarahkan pengguna ke halaman lain jika perkara tidak valid
      setValidRoute(false);
    }
  }, [perkara]);

  //========================== antd ===========================
  const [current, setCurrent] = useState(0);
  const [tagihan, setTagihan] = useState([]);
  const [dataModal, setDataModal] = useState({});
  const [dataPersonal, setDataPersonal] = useState(null);
  const [fileKtp, setFileKtp] = useState(null);
  const [indexDetail, setIndexDetail] = useState();

  const handleFileKtp = async (file) => {
    const compressedImage = await compressImage(file);
    setFileKtp(compressedImage);
  };

  const registerService = new RegisterService();
  const [open, setOpen] = useState(false);

  async function postRegisterCreditor(dataTagihan, dataPersonal) {
    try {
      console.log("data personal register", dataPersonal);
      const data = new FormData();
      data.append("creditorType", dataPersonal.values.tipeKreditor);
      data.append("name", dataPersonal.values.nama);
      data.append("address", dataPersonal.values.alamat);
      //TODO::nanti dihapus
      // data.append("creditorType", "separatis");
      data.append("phoneNumber", `0${dataPersonal.values.phone}`);
      data.append("email", dataPersonal.values.email);
      if (dataPersonal.values.hasOwnProperty("ktp")) {
        data.append("ktpNumber", dataPersonal.values.ktp);
      }
      //
      if (dataPersonal.hasOwnProperty("akta")) {
        data.append("photocopyAkta", dataPersonal.akta);
      }
      if (dataPersonal.hasOwnProperty("anggaran")) {
        data.append("photocopyAdart", dataPersonal.anggaran);
      }
      //
      if (dataPersonal.hasOwnProperty("fotoKtp")) {
        data.append("photocopyKtp", dataPersonal.fotoKtp);
      }
      if (dataPersonal.values.hasOwnProperty("attorneyName")) {
        data.append("attorney", "true");
        // data.append("attorneyName", dataPersonal.values.attorneyName);
        data.append("attorneyAddress", dataPersonal.values.alamatAttorney);
        data.append(
          "phoneNumberAttorney",
          `0${dataPersonal.values.phone_attorney}`
        );
        data.append("powerOfAttorney", dataPersonal.sk);
      }
      data.append("lawsuitId", perkara);
      data.forEach((value, key) => {
        console.log(key, `${value}`);
      });
      const res = await registerService.postRegisterCreditors(data);
      console.log("ini responsenya", res);
      if (res.response != null && res.response.data.status === "fail") {
        return message.error(JSON.stringify(res.response.data.message));
      }
      const creditorId = res.data.creditorId;
      await dataTagihan.forEach(async (element, index) => {
        const dataTagihan = new FormData();
        dataTagihan.append("invoiceNumber", element.invoiceNumber);
        dataTagihan.append("billNature", element.billNature);
        dataTagihan.append("principalAmount", element.principalAmount);
        dataTagihan.append("interest", element.interest);
        dataTagihan.append("amercement", element.amercement);
        if (
          element.hasOwnProperty("billRecapitulation") &&
          element.billRecapitulation !== undefined
        ) {
          dataTagihan.append("billRecapitulation", element.billRecapitulation);
        }
        if (
          element.hasOwnProperty("fundamentalBill") &&
          element.fundamentalBill !== undefined
        ) {
          dataTagihan.append("fundamentalBill", element.fundamentalBill);
        }
        if (
          element.hasOwnProperty("proofOfPayment") &&
          element.proofOfPayment !== undefined
        ) {
          dataTagihan.append("proofOfPayment", element.proofOfPayment);
        }
        dataTagihan.append("creditorId", creditorId);
        dataTagihan.forEach((value, key) => {});
        await registerService.postRegisterInvoice(dataTagihan);
      });
      Cookies.set(
        "dataRegister",
        JSON.stringify({
          creditorId: creditorId,
          dataTagihan: dataTagihan,
          dataPersonal: dataPersonal,
        }),
        {
          expires: 1,
        }
      );
      ToastNoify("Data Berhasil di Simpan");
      navigate("/register/upload", {
        state: {
          tagihan: tagihan,
          dataPersonal: dataPersonal,
        },
      });
    } catch (error) {
      ToastNoify(error);
    }
  }

  // const [form] = Form.useForm();
  // function handleFormFinish(formData) {
  //   console.log("Form data:", formData);
  //   // do something with form data
  // }
  useEffect(() => {}, [dataModal]);

  // function handleSubmit() {
  //   console.log("validation failed");
  //   form
  //     .validateFields()
  //     .then((values) => {
  //       handleFormFinish(values);
  //       console.log("ini valuenya bro", values);
  //     })
  //     .catch((error) => {
  //       console.log("Validation failed:", error);
  //     });
  // }

  const onFinish = (values) => {};

  const steps = [
    {
      title: "Data Diri",
      content: (
        <FormPersonalData
          setCurrent={setCurrent}
          current={current}
          onFinish={onFinish}
          setDataPersonal={setDataPersonal}
          dataPersonal={dataPersonal}
          handleFileKtp={handleFileKtp}
          fileKTP={fileKtp}
        />
      ),
    },
    {
      title: "Tagihan",
      content: <FormTagihan setTagihan={setTagihan} tagihan={tagihan} />,
    },

    {
      title: "Review",
      content: (
        <ReviewRegister
          dataPersonal={dataPersonal}
          tagihan={tagihan}
          dataModal={dataModal}
          fileKtp={fileKtp}
        />
      ),
    },
  ];
  const next = () => {
    setCurrent(current + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const prev = () => {
    setCurrent(current - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [modalDetail, setModalDetail] = useState(false);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));
  //========================== antd ===========================

  async function showModalDetail(props) {
    setModalDetail(true);
    setDataModal(tagihan[props]);
    setIndexDetail(props);
  }
  const contentPopup = tagihan.map((item, index) => (
    <Tooltip key={index} title="Tap to see details" placement="right">
      <div
        className="w-full cursor-pointer hover:bg-gray-200 p-2"
        onClick={() => showModalDetail(index)}
      >
        <p className="block">{item.invoiceNumber}</p>
      </div>
    </Tooltip>
  ));

  if (registered) {
    return <Navigate to="/register/upload" replace />;
  }
  if (!validRoute) {
    // Jika mitraOgan tidak ada dalam availableRoutes, arahkan pengguna ke halaman lain atau tampilkan pesan kesalahan
    return <Navigate to="/" replace />;
  }
  return (
    <main className="h-full overflow-y-auto">
      <ModalEditTagihanRegister
        tagihan={tagihan}
        modalDetail={modalDetail}
        setModalDetail={setModalDetail}
        dataModal={dataModal}
        indexDetail={indexDetail}
        setTagihan={setTagihan}
      />
      <div className="fixed bottom-4 right-0">
        <div className="bg-white p-4 rounded-l-lg w-[250px] shadow-2xl text-purple">
          <div className="flex items-center ">
            <a
              className="mx-3 align-center"
              href="https://wa.me/1234567890"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiOutlineWhatsApp className="text-4xl text-green-500"></AiOutlineWhatsApp>
            </a>
            <p>Tap me for question</p>
          </div>
        </div>
      </div>
      {/* {tagihan.length > 0 && ( */}
      {/* ===================== Popup Daftar Tagihan ============= */}
      <div
        className={`transition-all ease-in duration-1000 ${
          tagihan.length > 0 ? "visible" : "invisible"
        }`}
      >
        <Popover content={contentPopup} trigger="hover" title="Daftar Tagihan">
          <div className="fixed bottom-4 left-4">
            <Badge count={tagihan.length}>
              <div className="bg-white p-4 rounded-full shadow-2xl text-purple">
                <div className="flex items-center ">
                  <DatabaseOutlined className="text-4xl mx-2" />
                  <p>Daftar Tagihan</p>
                </div>
              </div>
            </Badge>
          </div>
        </Popover>
      </div>
      {/*  =================== BreadCrumb ====================== */}
      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800 p-4">
          {/* <img src={logo} alt={logo} className="w-72 mx-auto"></img> */}
          <Steps
            className="w-2/3 mx-auto mb-10"
            current={current}
            items={items}
          />
          <div className="mt-5">{steps[current].content}</div>
          <div
            className="flex flex-wrap justify-center"
            style={{
              marginTop: 10,
            }}
          >
            {current > 0 && (
              <button
                onClick={() => prev()}
                className="inline mx-4 ant-btn ant-btn-primary mt-5 text-purple-600 hover:bg-purple-700 hover:text-white py-2 px-5 rounded-md bg-white border border-purple"
              >
                Previous
              </button>
            )}
            {current < steps.length - 1 && current !== 0 && (
              <button
                onClick={() => {
                  if (tagihan.length === 0) {
                    message.error("Isi tagihan terlebih dahulu");
                  } else {
                    next();
                  }
                }}
                className={`inline mx-4 ant-btn ant-btn-primary mt-5 ${
                  tagihan.length === 0
                    ? "bg-gray-500"
                    : "bg-purple-600 hover:bg-purple-700"
                }   py-2 px-5 rounded-md text-white`}
              >
                {current === 0 ? "Next" : "Done"}
              </button>
            )}

            {current === steps.length - 1 && (
              <Popconfirm
                title="Pastikan data yang anda masukkan sudah sesuai"
                description="Apakah data yang anda masukkan sudah sesuai?"
                open={open}
                onConfirm={() => postRegisterCreditor(tagihan, dataPersonal)}
                onCancel={() => setOpen(false)}
                okButtonProps={{
                  style: {
                    background: "#6B46C1",
                    color: "#FFFFFF",
                    borderRadius: "4px",
                    padding: "0px 15px",
                  },
                }}
              >
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="inline mx-4 ant-btn ant-btn-primary mt-5 bg-purple-600 hover:bg-purple-700 py-2 px-5 rounded-md text-white"
                >
                  Done
                </button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;

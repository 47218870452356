import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthApi from "../AuthApi";
import MainDashboard from "../component/dashboard/MainDashboard";
import Header from "../component/template/Header";
import Sidebar from "../component/template/Sidebar";
import ToastNoify from "../utils/ToastNotify";

function Dashboard() {
  const Auth = React.useContext(AuthApi);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const { state } = useLocation();

  if (state) {
    ToastNoify("error", state.message);
  }

  useEffect(() => {
    Auth.setPageActive("dashboard");
  }, [Auth]);

  function toggleSideMenu() {
    const hamburger = document.getElementById("humbergerButton");
    hamburger.classList.toggle("hamburger-active");
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar isSideMenuOpen={isSideMenuOpen} />
      <div className="flex flex-col flex-1 w-full overflow-auto">
        <Header toggleSideMenu={toggleSideMenu} />
        <MainDashboard />
      </div>
    </div>
  );
}

export default Dashboard;

import ChartDashboard from "./ChartDashboard";

function MainDashboard() {
  return (
    <main className="h-full overflow-y-auto">
      <div className="">
        <ChartDashboard />
      </div>
    </main>
  );
}

export default MainDashboard;

/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InvoiceService from "../../app/Services/InvoiceService";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import AuthApi from "../../AuthApi";
import MainTagihan from "../../component/tagihan/MainTagihan";
import Header from "../../component/template/Header";
import LoadingSpinner from "../../component/template/LoadingSpinner";
import Sidebar from "../../component/template/Sidebar";
import ToastNoify from "../../utils/ToastNotify";

const Tagihan = () => {
  const Auth = React.useContext(AuthApi);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [dataPiutang, setDataPiutang] = useState([]);

  const invoiceService = new InvoiceService();
  useEffect(() => {
    getPiutang();
  }, [params]);

  const getPiutang = async () => {
    setLoading(true);
    const lawsuitId = params.lawsuitId;
    const accessToken = await RefreshTokenService();
    if (accessToken === "fail") {
      Auth.setAuth(false);
      Cookies.remove("refreshToken");
      Cookies.remove("user");
    }

    const result = await invoiceService.getPiutang({
      accessToken,
      lawsuitId,
    });

    if (result.status === "success") {
      console.log("log main tagihan getPiutang", result.data);
      setDataPiutang(result.data.piutang);
    } else {
      ToastNoify("error", result.message);
    }

    setLoading(false);
  };
  useEffect(() => {
    Auth.setPageActive("Daftar Piutang");
  }, [Auth]);

  function toggleSideMenu() {
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar isSideMenuOpen={isSideMenuOpen} />
      <div className="flex flex-col flex-1 w-full overflow-auto">
        <Header toggleSideMenu={toggleSideMenu} />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <MainTagihan
            dataPiutang={dataPiutang}
            setDataPiutang={setDataPiutang}
            getPiutang={getPiutang}
          />
        )}
      </div>
    </div>
  );
};

export default Tagihan;

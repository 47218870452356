import CONFIG from "./CONFIG";

const API_ENDPOINT = {
  AUTHENTICATION: `${CONFIG.BASE_URL}/authentication`,
  USER: `${CONFIG.BASE_URL}/users`,
  CREDITOR: `${CONFIG.BASE_URL}/creditors`,
  INVOICE: `${CONFIG.BASE_URL}/invoices`,
  PIUTANG: `${CONFIG.BASE_URL}/piutang`,
  LAWSUIT: `${CONFIG.BASE_URL}/lawsuit`,
  LAWSUITALL: `${CONFIG.BASE_URL}/lawsuit/all-column`,
};

export default API_ENDPOINT;

import { Collapse, Divider, Image } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { BsPrinterFill } from "react-icons/bs";
import ReactToPrint from "react-to-print";

const { Panel } = Collapse;

const ReviewRegister = (props) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [dataRegister, setDataRegister] = useState();

  function beforePrint() {
    // Cookies.set("dataRegister", JSON.stringify(props), { expires: 1 });
    // Cookies.set("dataRegisterTagihan", JSON.stringify(props.tagihan), {
    //   expires: 1,
    // });
    // const dataRegister = Cookies.get("dataRegister");
    // const dataRegisterTagihan = Cookies.get("dataRegisterTagihan");
    // console.log("ini data cookies", JSON.parse(dataRegister));
    // console.log("ini data tagihan", JSON.parse(dataRegisterTagihan));
    // Menyimpan objek props ke local storage
    localStorage.setItem("dataRegister", props);
    localStorage.setItem("dataRegisterTagihan", JSON.stringify(props.tagihan));

    // Mengambil objek props dari local storage
    const dataRegister = JSON.parse(
      localStorage.getItem("dataRegisterTagihan")
    );
    // setDataRegister(JSON.parse(localStorage.getItem("dataRegister")));
    const dataRegisterTagihan = JSON.parse(
      localStorage.getItem("dataRegisterTagihan")
    );

    console.log("ini test", dataRegister);
    // console.log("ini test", dataRegisterTagihan);
  }
  const printContentRef = useRef(null);
  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const objArray = Object.entries(props.dataPersonal.values).map(
    ([key, value]) => {
      const transformedKey = key
        .replace(/^[a-z]/, (match) => match.toUpperCase())
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return (
        <>
          {key === "attorneyName" && <Divider>Kuasa Hukum</Divider>}
          {key !== "surat kuasa" &&
            key !== "disabled" &&
            key !== "prefix" &&
            key !== "photocopyKtp" && (
              <p key={key}>
                {transformedKey}:{" "}
                {key === "phone" || key === "phone_attorney"
                  ? "+62 " + value
                  : value}
              </p>
            )}
        </>
      );
    }
  );

  return (
    <div className="flex flex-col overflow-y-auto md:flex-row">
      <div className="w-2/3 mx-auto">
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Data Pribadi" key="1">
            <div className="text-sm space-y-3 ">
              {objArray}
              <div className="foto flex flex-wrap gap-4">
                {props.dataPersonal.hasOwnProperty("fotoKtp") && (
                  <>
                    <Divider>Dokumen</Divider>
                    <div className="border border-purple rounded-md p-2 ">
                      <h4>Foto KTP: </h4>
                      <Image
                        height={100}
                        width={100}
                        src={URL.createObjectURL(props.dataPersonal.fotoKtp)}
                      ></Image>
                    </div>
                  </>
                )}
                {props.dataPersonal.hasOwnProperty("akta") && (
                  <>
                    <Divider>Dokumen</Divider>

                    <div className="border border-purple rounded-md p-2 ">
                      <h4>Akta: </h4>
                      <Image
                        height={100}
                        width={100}
                        src={URL.createObjectURL(props.dataPersonal.akta)}
                      ></Image>
                    </div>
                  </>
                )}
                {props.dataPersonal.hasOwnProperty("sk") && (
                  <div className="border border-purple rounded-md p-2 ">
                    <h4>SK: </h4>
                    <Image
                      height={100}
                      width={100}
                      src={URL.createObjectURL(props.dataPersonal.sk)}
                    ></Image>
                  </div>
                )}
                {props.dataPersonal.hasOwnProperty("anggaran") && (
                  <div className="border border-purple rounded-md p-2 ">
                    <h4>Anggaran: </h4>
                    <Image
                      height={100}
                      width={100}
                      src={URL.createObjectURL(props.dataPersonal.anggaran)}
                    ></Image>
                  </div>
                )}
                {props.dataPersonal.hasOwnProperty("lainnya") && (
                  <div className="border border-purple rounded-md p-2 ">
                    <h4>Lainnya: </h4>
                    <Image
                      height={100}
                      width={100}
                      src={URL.createObjectURL(props.dataPersonal.lainnya)}
                    ></Image>
                  </div>
                )}
              </div>
            </div>
          </Panel>
          <Panel header="Tagihan" key="2">
            Click tombol daftar tagihan untuk melihat detail tagihan
          </Panel>
        </Collapse>
        <div className="mt-4">
          {/* <ReactToPrint
            onBeforePrint={beforePrint}
            className="space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
            trigger={() => (
              <button className="print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600">
                <BsPrinterFill className="w-[20px] h-[20px]" />
                <span className="print:hidden font-semibold mr-4">
                  Save & Print
                </span>
              </button>
            )}
            content={() => printContentRef.current}
          ></ReactToPrint> */}
          <div
            className="printable hidden print:block print:mx-8"
            // className="printable mx-8"
            ref={printContentRef}
          >
            <h2 className="w-full text-center font-bold mb-16 border">
              FORMULIR PENGAJUAN KLAIM/TAGIHAN KEPADA PT GRIYA KARUNIA SEJAHTERA
              (DALAM PKPU)
            </h2>
            <div className="flex space-x-5 w-full">
              <table className="border">
                <thead>
                  <tr>
                    <th colSpan={2}>Data Kreditor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border text-sm p-3">Tipe Kreditor</td>
                    <td className="border text-sm p-3">
                      {capitalizeFirstLetter(
                        props.dataPersonal.values.tipeKreditor
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="border text-sm p-3">Nama</td>
                    <td className="border text-sm p-3">
                      {capitalizeFirstLetter(props.dataPersonal.values.nama)}
                    </td>
                  </tr>
                  <tr>
                    <td className="border text-sm p-3">Alamat</td>
                    <td className="border text-sm p-3">
                      {capitalizeFirstLetter(props.dataPersonal.values.alamat)}
                    </td>
                  </tr>
                  <tr>
                    <td className="border text-sm p-3">No Telepon</td>
                    <td className="border text-sm p-3">
                      {`0${props.dataPersonal.values.phone}`}
                    </td>
                  </tr>
                  <tr>
                    <td className="border text-sm p-3">Email</td>
                    <td className="border text-sm p-3">
                      {props.dataPersonal.values.email}
                    </td>
                  </tr>
                </tbody>
              </table>
              {props.dataPersonal.values.hasOwnProperty("attorneyName") &&
                props.dataPersonal.values.attorneyName !== null && (
                  <table className="border">
                    <thead>
                      <tr>
                        <th colSpan={2}>Data Kuasa Hukum</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border text-sm p-3">Nama</td>
                        <td className="border text-sm p-3">
                          {capitalizeFirstLetter(
                            props.dataPersonal.values.attorneyName
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="border text-sm p-3">Alamat</td>
                        <td className="border text-sm p-3">
                          {capitalizeFirstLetter(
                            props.dataPersonal.values.alamatAttorney
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="border text-sm p-3">No Telepon</td>
                        <td className="border text-sm p-3">
                          {`0${props.dataPersonal.values.phone_attorney}`}
                        </td>
                      </tr>
                      <tr>
                        <td className="border text-sm p-3">Email</td>
                        <td className="border text-sm p-3">
                          {props.dataPersonal.values.email}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
            </div>

            <div className="piutang my-4">
              <table className="border">
                <thead>
                  <tr>
                    <th colSpan={7}>Rincian Piutang</th>
                  </tr>
                  <tr>
                    <th className="border truncate text-sm p-2">No</th>
                    <th className="border truncate text-sm p-2">
                      Sifat Tagihan
                    </th>
                    <th className="border truncate text-sm p-2">No Tagihan</th>
                    <th className="border truncate text-sm p-2">
                      Tagihan Pokok
                    </th>
                    <th className="border truncate text-sm p-2">Bunga</th>
                    <th className="border truncate text-sm p-2">Denda</th>
                    <th className="border truncate text-sm p-2">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {props.tagihan.map((data, index) => (
                    <tr>
                      <td className="border text-sm p-3">{index + 1}</td>
                      <td className="border text-sm p-3">
                        {/* {capitalizeFirstLetter(data.billNature)} */}
                      </td>
                      <td className="border text-sm p-3">
                        {data.invoiceNumber}
                      </td>
                      <td className="border text-sm p-3 truncate">
                        {formatter(`${data.principalAmount || "0"}`)}
                      </td>
                      <td className="border text-sm p-3 truncate">
                        {formatter(`${data.interest || "0"}`)}
                      </td>
                      <td className="border text-sm p-3 truncate">
                        {formatter(`${data.amercement || "0"}`)}
                      </td>
                      <td className="border text-sm p-3 truncate">
                        {formatter(
                          `${
                            (parseInt(data.principalAmount) || 0) +
                            (parseInt(data.interest) || 0) +
                            (parseInt(data.amercement) || 0)
                          }`
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="text-sm">
              Demikian rincian piutang kami kepada PT Griya Karunia Sejahtera
              (Dalam PKPU) dan dengan ini menyatakan dan menjamin bahwa
              perhitungan piutang kami sebagaimana tertera diatas adalah benar
            </p>
            <div className="ttd mt-10">
              <table className="w-[600px]">
                <tbody>
                  <tr className="">
                    <td>Jakarta, .....................</td>
                  </tr>
                  <tr>
                    <td>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">PEMBERI KUASA</td>
                    <td className="w-[150px]"> </td>
                    <td className="text-center">PENERIMA KUASA</td>
                  </tr>
                  <tr className=" min-h-[150px]">
                    <td className="">
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <hr />
                    </td>
                    <td> </td>
                    <td>
                      <hr />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewRegister;

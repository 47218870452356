import React from "react";
import { useState } from "react";
import ModalAddPerkara from "./ModalAddPerkara";
import ModalEditPerkara from "./ModalEditPerkara";
import TablePerkara from "./TablePerkara";

const MainPerkara = (props) => {
  const [dummyDataModal, setDummyDataModal] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  function handleRowClick(data) {
    setDummyDataModal(data);
    setModalEdit(true);
  }

  return (
    <main className="h-full overflow-y-auto">
      <TablePerkara
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
        dummyDataModal={dummyDataModal}
        setDummyDataModal={setDummyDataModal}
        handleRowClick={handleRowClick}
        dataPerkara={props.dataPerkara}
        setDataPerkara={props.setDataPerkara}
      />
      <ModalAddPerkara
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        getDataPerkara={props.getDataPerkara}
      />
      <ModalEditPerkara
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
        dummyDataModal={dummyDataModal}
        setDummyDataModal={setDummyDataModal}
      />
    </main>
  );
};

export default MainPerkara;

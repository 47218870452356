import React from "react";
import ReactPaginate from "react-paginate";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { AiFillSetting } from "react-icons/ai";

import { BsPrinterFill } from "react-icons/bs";

import { InputNumber, message, Modal, Row, Select } from "antd";
import { Option } from "antd/es/mentions";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import Search from "antd/es/input/Search";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import axios from "axios";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";

const TableTagihan = (props) => {
  const [tampunganData, setTampunganData] = useState([]);
  const [isDataDifferent, setIsDataDifferent] = useState(false);

  const [modalPrint, setModalPrint] = useState(false);
  const [inputNumberValue, setInputNumberValue] = useState(0);
  const [batasPrint, setBatasPrint] = useState();
  const [catatanSikapPengurus, setCatatanSikapPengurus] = useState();

  useEffect(() => {
    setTampunganData([...props.dataPiutang]);
    console.log("ini terpanggil", props.dataPiutang);
  }, [props.dataPiutang]);
  useEffect(() => {
    console.log("tampunganData:", tampunganData);
  }, [tampunganData]);

  const printContentRef = useRef(null);
  const [filter, setFilter] = useState("all");

  const savePiutang = async () => {
    const token = await RefreshTokenService();
    const excludedKeys = ["identitasKreditor", "creditor_id", "identitasKuasa"];

    const payload = tampunganData.map((item) => {
      // Membuat salinan objek dengan menghapus kunci yang tidak diinginkan
      const filteredItem = Object.keys(item)
        .filter((key) => !excludedKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});

      return filteredItem;
    });
    console.log("ini payload", payload);
    const res = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "PUT",
      data: payload,
      url: `${API_ENDPOINT.PIUTANG}`,
    });
    console.log("res table tagihan", res);
    if (res.status === 200) {
      props.getPiutang();
    } else {
      message.error(res.data.message);
    }
  };

  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const parser = (value) => value.replace(/Rp.\s?|(,*)/g, "");
  return (
    <div className="m-4">
      <Modal
        title="Konfigurasi Print"
        open={modalPrint}
        onOk={() => {
          console.log("batas print", inputNumberValue);
          setBatasPrint(inputNumberValue);
          setModalPrint(false);
        }}
        onCancel={() => {
          setModalPrint(false);
        }}
      >
        <InputNumber
          value={inputNumberValue}
          className="min-w-[150px]"
          onChange={(value) => setInputNumberValue(value)}
          placeholder={"Batas halaman"}
        />
      </Modal>
      <div
        className="w-full overflow-hidden rounded-lg shadow-xs border"
        ref={printContentRef}
      >
        <div className="w-full overflow-x-scroll">
          <Row>
            <button
              onClick={() => props.setModalAdd(true)}
              className="print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
            >
              <MdOutlineLibraryAdd className="w-[20px] h-[20px]" />
              <span className="font-semibold mr-4">Menambahkan Tagihan</span>
            </button>

            {/* <button
              onClick={() => {
                const printContent = printContentRef.current;
                const originalContents = document.body.innerHTML;

                // Simpan konten tabel yang akan dicetak dalam variabel
                const printContents = printContent.innerHTML;

                // Sementara ganti konten jendela dengan konten tabel yang akan dicetak
                document.body.innerHTML = printContents;

                // Cetak
                window.print();

                // Kembalikan konten asli ke jendela setelah mencetak selesai atau dibatalkan
                document.body.innerHTML = originalContents;
              }}
              className="print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
            ></button> */}
            <ReactToPrint
              className="space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
              trigger={() => (
                <button className="print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600">
                  <BsPrinterFill className="w-[20px] h-[20px]" />
                  <span className="print:hidden font-semibold mr-4">Print</span>
                </button>
              )}
              content={() => printContentRef.current}
            ></ReactToPrint>
            <button
              className="print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
              onClick={() => {
                console.log("terpanggil");
                setModalPrint(true);
              }}
            >
              <AiFillSetting className="w-[20px] h-[20px] " />
              <span className="print:hidden font-semibold mr-4">
                Konfigurasi Halaman Print
              </span>
            </button>
            {isDataDifferent && (
              <button
                onClick={() => savePiutang()}
                className="print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
              >
                <MdOutlineLibraryAdd className="w-[20px] h-[20px]" />
                <div className="font-semibold">Save</div>
              </button>
            )}
            {isDataDifferent && (
              <button
                onClick={() => {
                  setTampunganData([...props.dataPiutang]);
                  setIsDataDifferent(false);
                }}
                className="print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
              >
                <MdOutlineLibraryAdd className="w-[20px] h-[20px]" />
                <div className="font-semibold">Reset</div>
              </button>
            )}
            <h2 className="w-full text-center print:block hidden text-2xl font-semibold text-gray-500 mb-5">
              Daftar Piutang Kreditor Yang di Akui dan/atau di Bantah Sementara
              Oleh Tim Pengurus PT Griya Karunia Sejahtera (Dalam PKPU)
            </h2>
          </Row>
          <div className="flex print:hidden">
            <Search
              className="m-4 bg-primary text-primary rounded-md hover:bg-primary"
              style={{
                width: 300,
              }}
              placeholder="Cari Kreditor"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={(value) => console.log(value)}
            />
            <Select
              className="m-4"
              defaultValue={filter}
              style={{
                width: 140,
              }}
              size="large"
              onChange={(value) => setFilter(value)}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "nama",
                  label: "Nama",
                },
                {
                  value: "alamat",
                  label: "Alamat",
                },
                {
                  value: "noTelepon",
                  label: "No Telepon",
                },
                {
                  value: "email",
                  label: "Email",
                },
                {
                  value: "kuasaHukum",
                  label: "Kuasa Hukum",
                },
                {
                  value: "sifatKreditor",
                  label: "Sifat (Kreditor)",
                },
                {
                  value: "sifatDebitor",
                  label: "Sifat (Debitor)",
                },
              ]}
            />
          </div>
          <table className="w-full table-auto whitespace-no-wrap">
            <thead className="">
              <tr className="text-xs text-center font-semibold tracking-wide text-gray-500 uppercase dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                <th rowSpan="3" className=" border">
                  No
                </th>
                <th rowSpan="3" colSpan="2" className=" border">
                  Identitas Kreditur
                </th>
                <th rowSpan="1" colSpan="4" className=" border">
                  Piutang Kreditor
                </th>
                <th rowSpan="3" className="border min-w-[200px]">
                  Catatan Kekurangan (Dokumen)
                </th>
                <th colSpan="3" className=" truncate border">
                  Catatan dan Laporan dari Debitor
                </th>
                <th colSpan="6" className="border">
                  Sikap Pengurus
                </th>
                <th colSpan="2" rowSpan={2} className="border">
                  Tanda Tangan Persetujuan Atas Sikap Pengurus
                </th>
              </tr>
              <tr className="text-xs text-center font-semibold tracking-wide text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                <th rowSpan="2" className=" border">
                  Sifat
                </th>
                <th rowSpan="1" colSpan="3" className=" border">
                  Rincian Piutang
                </th>
                <th rowSpan="1" colSpan="3" className=" border">
                  Rincian Piutang
                </th>
                <th colSpan="3" className="border">
                  Diakui(Rp)
                </th>
                <th colSpan="2" className="border">
                  Dibantah(Rp)
                </th>
                <th rowSpan="2" className="border min-w-[200px]">
                  Catatan
                </th>
              </tr>
              <tr className="text-xs text-center font-semibold tracking-wide text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                <th className="border">Rincian Piutang</th>
                <th className="border min-w-[150px]">Rp</th>
                <th className="border min-w-[150px]">Jumlah (Rp)</th>
                <th className="border truncate">Rincian Piutang</th>
                <th className="border min-w-[150px]">Rp</th>
                <th className="border min-w-[150px]">Jumlah (Rp)</th>
                <th className="border min-w-[150px]">Status</th>
                <th className="border">Rincian Piutang</th>
                <th className="border min-w-[150px]">RP</th>
                <th className="border">Rincian Piutang</th>
                <th className="border min-w-[150px]">Jumlah (Rp)</th>
                <th className="border min-w-[150px]">Kreditor</th>
                <th className="border min-w-[150px]">Debitor</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
              {tampunganData.map((data, i) => (
                <React.Fragment key={i}>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td
                      rowSpan="11"
                      className="text-center border print:table-cell"
                    >
                      {i + 1}
                    </td>
                    <td
                      colSpan={2}
                      className="truncate border text-center font-semibold"
                    >
                      Identitas Perusahaan
                    </td>
                    <td rowSpan={10} className="border">
                      <Select
                        value={data.data_kreditor.sifat}
                        bordered={false}
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_kreditor: {
                              ...updatedData[i].data_kreditor,
                              sifat: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      >
                        <Option key="konkuren" value="Konkuren">
                          Konkuren
                        </Option>
                        <Option key="separatis" value="Separatis">
                          Separatis
                        </Option>
                        <Option key="preferen" value="Preferen">
                          Preferen
                        </Option>
                      </Select>
                    </td>
                    <td className="border">Pokok</td>
                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_kreditor.pokok !== ""
                            ? data.data_kreditor.pokok
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        // onChange={(value) => {
                        //   const updatedData = [...tampunganData];
                        //   updatedData[i].data_kreditor.pokok = value;
                        //   setTampunganData(updatedData);
                        //   console.log("props", props.dataPiutang);
                        //   console.log("tumpangan", tampunganData);
                        // }}
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_kreditor: {
                              ...updatedData[i].data_kreditor,
                              pokok: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">
                      {data.data_kreditor.pokok !== ""
                        ? formatter(data.data_kreditor.pokok)
                        : formatter("0")}
                    </td>
                    <td className="border" rowSpan={10}></td>
                    <td className="border">Pokok</td>
                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_debitor.pokok !== ""
                            ? data.data_debitor.pokok
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_debitor: {
                              ...updatedData[i].data_debitor,
                              pokok: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">
                      {data.data_debitor.pokok !== ""
                        ? formatter(data.data_debitor.pokok)
                        : formatter("0")}
                    </td>
                    <td className="border" rowSpan={10}>
                      <Select
                        className="min-w-[150px]"
                        value={data.data_pengurus.sifat}
                        bordered={false}
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              sifat: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      >
                        <Option key="konkuren" value="Konkuren">
                          Konkuren
                        </Option>
                        <Option key="separatis" value="Separatis">
                          Separatis
                        </Option>
                        <Option key="preferen" value="Preferen">
                          Preferen
                        </Option>
                      </Select>
                    </td>
                    <td className="border">Pokok</td>

                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_pengurus.dataDiAkui.pokok !== ""
                            ? data.data_pengurus.dataDiAkui.pokok
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              dataDiAkui: {
                                ...updatedData[i].data_pengurus.dataDiAkui,
                                pokok: value,
                              },
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">Pokok</td>
                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_pengurus.dataDiBantah.pokok !== ""
                            ? data.data_pengurus.dataDiBantah.pokok
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              dataDiBantah: {
                                ...updatedData[i].data_pengurus.dataDiBantah,
                                pokok: value,
                              },
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border max-w-[250px] relative" rowSpan={10}>
                      <div className="absolute inset-0 p-1">
                        <select
                          className="h-full w-full overflow-y-auto whitespace-normal text-justify"
                          placeholder="Catatan Sikap Pengurus"
                          value={catatanSikapPengurus}
                        >
                          <option
                            key="separatisHakTanggungan"
                            value="separatisHakTanggungan"
                          >
                            [Separatis - Hak Tanggungan] Bahwa tagihan ini
                            merupakan kelompok tagihan yang dijamin dengan hak
                            kebendaan karena dilindungi oleh undang-undang nomor
                            4 tahun 1996 tentang hak tanggungan jo. Pasal 1138
                            KUH Perdata dan Pasal 55 Undang-Undang No. 37 Tahun
                            2004 Tentang Kepailitan dan Penundaan Kewajiban
                            Pembayaran Utang.
                          </option>
                          <option
                            key="separatisFidusia"
                            value="separatisFidusia"
                          >
                            [Separatis - Fidusia] Bahwa tagihan ini merupakan
                            kelompok tagihan yang dijamin dengan hak kebendaan
                            karena dilindungi oleh undang-undang nomor 42 tahun
                            1999 tentang jaminan fidusia Fidusia jo. Pasal 1138
                            KUH Perdata dan Pasal 55 Undang-Undang No. 37 Tahun
                            2004 Tentang Kepailitan dan Penundaan Kewajiban
                            Pembayaran Utang.
                          </option>
                          <option
                            key="separatisHipotik"
                            value="separatisHipotik"
                          >
                            [Separatis - Hipotik]Bahwa tagihan ini merupakan
                            kelompok tagihan yang dijamin dengan hak kebendaan
                            karena dilindungi oleh Pasal 1162 Kitab
                            Undang-Undang Hukum Perdata ("KUHPerdata") jo. Pasal
                            1138 KUH Perdata dan Pasal 55 Undang-Undang No. 37
                            Tahun 2004 Tentang Kepailitan dan Penundaan
                            Kewajiban Pembayaran Utang.
                          </option>
                          <option key="separatisGadai" value="separatisGadai">
                            [Separatis Gadai] Bahwa tagihan ini merupakan
                            kelompok tagihan yang dijamin dengan hak kebendaan
                            karena dilindungi oleh Pasal 1150 Kitab
                            Undang-Undang Hukum Perdata ("KUHPerdata") jo. Pasal
                            1138 KUH Perdata dan Pasal 55 Undang-Undang No. 37
                            Tahun 2004 Tentang Kepailitan dan Penundaan
                            Kewajiban Pembayaran Utang.
                          </option>
                          <option
                            key="preferenKaryawan"
                            value="preferenKaryawan"
                          >
                            [Preferen - Karyawan] Bahwa tagihan ini tergolong
                            dalam kelompok tagihan yang memiliki hak mendahului
                            karena memenuhi kriteria Pasal 1139 KUHPerdata jo.
                            Pasal 95 ayat (4) UU No. 13 Tahun 2003 tentang
                            Ketenagakerjaan.
                          </option>
                          <option key="preferenPajak" value="preferenPajak">
                            [Preferen - Pajak] Bahwa tagihan ini tergolong dalam
                            kelompok tagihan yang memiliki hak mendahului karena
                            memenuhi kriteria Pasal 1137 KUHPerdata jo. Pasal 21
                            UU No. 16 Tahun 2009 tentang Ketentuan umum dan Tata
                            Cara Perpajakan.
                          </option>
                          <option key="konkuren" value="konkuren">
                            [Konkuren] Bahwa tagihan kreditor{" "}
                            {data.identitasKreditor.nama}
                            tergolong dalam kelompok tagihan bersifat konkuren,
                            karena jenis tagihannya tidak termasuk kelompok
                            tagihan yang diistimewakan/didahulukan berdasarkan
                            ketentuan Undang-Undang yang berlaku.
                          </option>
                          <option key="pkpuDiAkui" value="pkpuDiAkui">
                            [PKPU - Diakui] Bahwa Pengakuan atau Bantahan Tim
                            Pengurus atas nilai dan sifat tagihan yang diajukan
                            oleh Kreditor yang didasarkan atas bukti-bukti
                            tagihan yang disampaikan oleh para kreditor. Tim
                            Pengurus berhak menarik kembali setiap pengakuan
                            atau bantahan yang diajukan oleh para Kreditor
                            sebagaimana ketentuan Pasal 279 UU No.37 Tahun 2004
                            Tentang Kepailitan dan PKPU.
                          </option>
                          <option key="pailitDiAKui" value="pailitDiAKui">
                            [Pailit - Diakui] Bahwa Pengakuan atau Bantahan Tim
                            Kurator atas nilai dan sifat tagihan yang diajukan
                            oleh Kreditor yang didasarkan atas bukti-bukti
                            tagihan yang disampaikan oleh para kreditor. Tim
                            Kurator berhak menarik kembali setiap pengakuan atau
                            bantahan yang diajukan oleh para Kreditor
                            sebagaimana ketentuan 124 ayat (3) UU No.37 Tahun
                            2004 Tentang Kepailitan dan PKPU.
                          </option>
                          <option key="pkpuDiBantah" value="pkpuDiBantah">
                            [PKPU - Dibantah] Bahwa Pengakuan atau Bantahan Tim
                            Pengurus atas nilai dan sifat tagihan yang diajukan
                            oleh Kreditor yang didasarkan atas bukti-bukti
                            tagihan yang disampaikan oleh para kreditor. Tim
                            Pengurus berhak menarik kembali setiap pengakuan
                            atau bantahan yang diajukan oleh para Kreditor
                            sebagaimana ketentuan Pasal 279 UU No.37 Tahun 2004
                            Tentang Kepailitan dan PKPU.
                          </option>
                          <option key="pailitDiBantah" value="pailitDiBantah">
                            [Pailit - Dibantah] Bahwa Pengakuan atau Bantahan
                            Tim Kurator atas nilai dan sifat tagihan yang
                            diajukan oleh Kreditor yang didasarkan atas
                            bukti-bukti tagihan yang disampaikan oleh para
                            kreditor. Tim Kurator berhak menarik kembali setiap
                            pengakuan atau bantahan yang diajukan oleh para
                            Kreditor sebagaimana ketentuan 124 ayat (3) UU No.37
                            Tahun 2004 Tentang Kepailitan dan PKPU.
                          </option>
                          <option
                            key="separatisKonkurenDiBantah"
                            value="separatisKonkurenDiBantah"
                          >
                            [Separatis Konkuren - Dibantah] Bahwa tagihan yang
                            diajukan oleh Kreditor
                            {data.identitasKreditor.nama}
                            tidak didukung oleh bukti tagihan yang cukup untuk
                            menjadi dasar timbulnya piutang.
                          </option>
                          <option
                            key="preferenDiBantah"
                            value="preferenDiBantah"
                          >
                            [Preferen - Dibantah] Bahwa Tim Pengurus membantah
                            sementara nilai tagihan PT…. sebesar Rp…...
                            dikarenakan telah dilakukan pembayaran berdasarkan
                            bukti-bukti terkait.
                          </option>
                        </select>
                      </div>
                    </td>
                    <td rowSpan={10} className="border"></td>
                    <td rowSpan={10} className="border"></td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td className="border">Nama</td>
                    <td className="border">{data.identitasKreditor.name}</td>
                    <td className="border">Bunga</td>
                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_kreditor.bunga !== ""
                            ? data.data_kreditor.bunga
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_kreditor: {
                              ...updatedData[i].data_kreditor,
                              bunga: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">
                      {data.data_kreditor.bunga !== ""
                        ? formatter(data.data_kreditor.bunga)
                        : formatter("0")}
                    </td>
                    <td className="border">Bunga</td>

                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_debitor.bunga !== ""
                            ? data.data_debitor.bunga
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_debitor: {
                              ...updatedData[i].data_debitor,
                              bunga: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">
                      {data.data_debitor.bunga !== ""
                        ? formatter(data.data_debitor.bunga)
                        : formatter("0")}
                    </td>
                    <td className="border">Bunga</td>
                    <td className="border">
                      <InputNumber
                        controls={false}
                        value={
                          data.data_pengurus.dataDiAkui.bunga !== ""
                            ? data.data_pengurus.dataDiAkui.bunga
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              dataDiAkui: {
                                ...updatedData[i].data_pengurus.dataDiAkui,
                                bunga: value,
                              },
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">Bunga</td>
                    <td className="border">
                      <InputNumber
                        controls={false}
                        value={
                          data.data_pengurus.dataDiBantah.bunga !== ""
                            ? data.data_pengurus.dataDiBantah.bunga
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              dataDiBantah: {
                                ...updatedData[i].data_pengurus.dataDiBantah,
                                bunga: value,
                              },
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td className="border">Alamat</td>
                    <td className="border">{data.identitasKreditor.address}</td>
                    <td className="border">Denda</td>
                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_kreditor.denda !== ""
                            ? data.data_kreditor.denda
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_kreditor: {
                              ...updatedData[i].data_kreditor,
                              denda: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">
                      {data.data_kreditor.denda !== ""
                        ? formatter(data.data_kreditor.denda)
                        : formatter("0")}
                    </td>
                    <td className="border">Denda</td>
                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_debitor.denda !== ""
                            ? data.data_debitor.denda
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_debitor: {
                              ...updatedData[i].data_debitor,
                              denda: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">
                      {data.data_debitor.denda !== ""
                        ? formatter(data.data_debitor.denda)
                        : formatter("0")}
                    </td>
                    <td className="border">Denda</td>
                    <td className="border">
                      <InputNumber
                        controls={false}
                        value={
                          data.data_pengurus.dataDiAkui.denda !== ""
                            ? data.data_pengurus.dataDiAkui.denda
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              dataDiAkui: {
                                ...updatedData[i].data_pengurus.dataDiAkui,
                                denda: value,
                              },
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">Denda</td>
                    <td className="border">
                      <InputNumber
                        controls={false}
                        value={
                          data.data_pengurus.dataDiBantah.denda !== ""
                            ? data.data_pengurus.dataDiBantah.denda
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              dataDiBantah: {
                                ...updatedData[i].data_pengurus.dataDiBantah,
                                denda: value,
                              },
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td className="border">No Telepon</td>
                    <td className="border">
                      {data.identitasKreditor.phone_number}
                    </td>
                    <td className="border">Biaya Lainnya</td>
                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_kreditor.lainya !== ""
                            ? data.data_kreditor.lainya
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_kreditor: {
                              ...updatedData[i].data_kreditor,
                              lainya: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">
                      {data.data_kreditor.lainya !== ""
                        ? formatter(data.data_kreditor.lainya)
                        : formatter("0")}
                    </td>
                    <td className="border">Biaya Lainnya</td>
                    <td>
                      <InputNumber
                        controls={false}
                        value={
                          data.data_debitor.lainya !== ""
                            ? data.data_debitor.lainya
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_debitor: {
                              ...updatedData[i].data_debitor,
                              lainya: value,
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">
                      {data.data_debitor.lainya !== ""
                        ? formatter(data.data_debitor.lainya)
                        : formatter("0")}
                    </td>
                    <td className="border">Biaya Lainnya</td>
                    <td className="border">
                      <InputNumber
                        controls={false}
                        value={
                          data.data_pengurus.dataDiAkui.lainya !== ""
                            ? data.data_pengurus.dataDiAkui.lainya
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              dataDiAkui: {
                                ...updatedData[i].data_pengurus.dataDiAkui,
                                lainnya: value,
                              },
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                    <td className="border">Biaya Lainnya</td>
                    <td className="border">
                      <InputNumber
                        controls={false}
                        value={
                          data.data_pengurus.dataDiBantah.lainya !== ""
                            ? data.data_pengurus.dataDiBantah.lainya
                            : 0
                        }
                        bordered={false}
                        formatter={formatter}
                        parser={parser}
                        className="w-full"
                        onChange={(value) => {
                          const updatedData = [...tampunganData];
                          updatedData[i] = {
                            ...updatedData[i],
                            data_pengurus: {
                              ...updatedData[i].data_pengurus,
                              dataDiBantah: {
                                ...updatedData[i].data_pengurus.dataDiBantah,
                                lainya: value,
                              },
                            },
                          };
                          setTampunganData(updatedData);
                          setIsDataDifferent(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td className="border">Email</td>
                    <td className="border">{data.identitasKreditor.email}</td>
                    <td className="border">Subtotal</td>
                    <td className="border">
                      <div className="p-3">
                        {formatter(
                          (parseInt(data.data_kreditor.pokok) || 0) +
                            (parseInt(data.data_kreditor.denda) || 0) +
                            (parseInt(data.data_kreditor.bunga) || 0) +
                            (parseInt(data.data_kreditor.lainya) || 0)
                        )}
                      </div>
                    </td>
                    <td className="border">
                      {formatter(
                        (parseInt(data.data_kreditor.pokok) || 0) +
                          (parseInt(data.data_kreditor.denda) || 0) +
                          (parseInt(data.data_kreditor.bunga) || 0) +
                          (parseInt(data.data_kreditor.lainya) || 0)
                      )}
                    </td>
                    <td className="border">Subtotal</td>
                    <td className="border">
                      <div className="p-3">
                        {formatter(
                          (parseInt(data.data_debitor.pokok) || 0) +
                            (parseInt(data.data_debitor.bunga) || 0) +
                            (parseInt(data.data_debitor.denda) || 0) +
                            (parseInt(data.data_debitor.lainya) || 0)
                        )}
                      </div>
                    </td>
                    <td className="border">
                      {formatter(
                        (parseInt(data.data_debitor.pokok) || 0) +
                          (parseInt(data.data_debitor.bunga) || 0) +
                          (parseInt(data.data_debitor.denda) || 0) +
                          (parseInt(data.data_debitor.lainya) || 0)
                      )}
                    </td>
                    <td className="border">Subtotal</td>
                    <td className="border">
                      <div className="p-3">
                        {formatter(
                          (parseInt(data.data_pengurus.dataDiAkui.pokok) || 0) +
                            (parseInt(data.data_pengurus.dataDiAkui.bunga) ||
                              0) +
                            (parseInt(data.data_pengurus.dataDiAkui.denda) ||
                              0) +
                            (parseInt(data.data_pengurus.dataDiAkui.lainya) ||
                              0)
                        )}
                      </div>
                    </td>
                    <td className="border">Subtotal</td>
                    <td className="border">
                      <div className="p-3">
                        {formatter(
                          (parseInt(data.data_pengurus.dataDiBantah.pokok) ||
                            0) +
                            (parseInt(data.data_pengurus.dataDiBantah.bunga) ||
                              0) +
                            (parseInt(data.data_pengurus.dataDiBantah.denda) ||
                              0) +
                            (parseInt(data.data_pengurus.dataDiBantah.lainya) ||
                              0)
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td
                      className="border text-center font-semibold"
                      colSpan="2"
                    >
                      Identitas Kuasa
                    </td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                    <td className="border" rowSpan={5}></td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td className="border">Nama Kantor Hukum</td>
                    <td className="border">{data.identitasKuasa.name}</td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td className="border">Alamat</td>
                    <td className="border">
                      {data.identitasKuasa.attorney_address}
                    </td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td className="border">No Telepon</td>
                    <td className="border">
                      {data.identitasKuasa.phone_number_attorney}
                    </td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td className="border">Email</td>
                    <td className="border">{data.identitasKuasa.email}</td>
                  </tr>
                  <tr
                    className={`${
                      batasPrint !== null &&
                      batasPrint !== 0 &&
                      i + 1 > batasPrint
                        ? "print:hidden"
                        : "print:break-inside-avoid print:table-row"
                    }`}
                  >
                    <td colSpan={19} className="border font-semibold">
                      Total
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-wrap justify-between px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
        <p className="flex items-center col-span-3">
          Total Data : 0
          <br /> Halaman : 1 Dari 1
        </p>
        <nav
          key={props.totalData}
          role="navigation"
          className="col-span-4 mt-2 sm:mt-4 sm:justify-end"
        >
          <ReactPaginate
            className="flex flex-wrap justify-center"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={props.totalPage}
            onPageChange={props.changePage}
            containerClassName={"flex"}
            pageLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white dark:text-gray-400 dark:bg-gray-700"
            }
            previousLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            nextLinkClassName={
              " text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            breakClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            activeLinkClassName={
              "active-paginate dark:text-white dark:bg-gray-700"
            }
            disabledLinkClassName={
              "disable-paginate hover:bg-gray-300 hover:text-gray-500"
            }
          />
        </nav>
      </div>
    </div>
  );
};

export default TableTagihan;

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AuthApi from "../../AuthApi";
import Header from "../template/Header";
import Sidebar from "../template/Sidebar";
import MainAdmin from "./MainAdmin";

const Admin = () => {
  const Auth = React.useContext(AuthApi);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  function toggleSideMenu() {
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  useEffect(() => {
    Auth.setPageActive("Admin");
  }, [Auth]);
  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar isSideMenuOpen={isSideMenuOpen} />
      <div className="flex flex-col flex-1 w-full overflow-auto">
        <Header toggleSideMenu={toggleSideMenu} />
        <MainAdmin />
      </div>
    </div>
  );
};

export default Admin;

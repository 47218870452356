import { Select } from "antd";
import Search from "antd/es/input/Search";
import React from "react";
import { useState } from "react";
import { MdOutlineLibraryAdd } from "react-icons/md";
import ReactPaginate from "react-paginate";

const TableAdmin = (props) => {
  const [filter, setFilter] = useState("all");
  const data = props.dataAdmin ? null : props.dataAdmin;
  return (
    <div className="m-4">
      <div className="w-full overflow-hidden rounded-lg shadow-xs border">
        <div className="w-full overflow-x-scroll">
          <button
            onClick={() => props.setModalAdd(true)}
            className="print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
          >
            <MdOutlineLibraryAdd className="w-[20px] h-[20px]" />
            <span className="font-semibold mr-4">Tambah Admin</span>
          </button>
          <div className="flex print:hidden">
            <Search
              className="m-4 bg-primary text-primary rounded-md hover:bg-primary"
              style={{
                width: 300,
              }}
              placeholder="Cari Admin"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={(value) => console.log(value)}
            />
            <Select
              className="m-4"
              defaultValue={filter}
              style={{
                width: 140,
              }}
              size="large"
              onChange={(value) => setFilter(value)}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "nama",
                  label: "Nama",
                },
                {
                  value: "email",
                  label: "Email",
                },
              ]}
            />
          </div>
          <table className="table-auto whitespace-no-wrap">
            <thead className="">
              <tr className="text-xs text-center font-semibold tracking-wide text-gray-500 uppercase dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                <th className="border">Nama</th>
                <th className="border">Email</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 text-center">
              {props.dataAdmin &&
                props.dataAdmin.map((data, index) => (
                  <tr key={index}>
                    <td className="border text-left">{data.name}</td>
                    <td className="border text-left">{data.email}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-wrap justify-between px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
        <p className="flex items-center col-span-3">
          Total Data : {props.totalData}
          <br /> Halaman : {props.page} Dari {props.totalPage}
        </p>
        <nav
          key={props.totalData}
          role="navigation"
          className="col-span-4 mt-2 sm:mt-4 sm:justify-end"
        >
          <ReactPaginate
            className="flex flex-wrap justify-center"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={props.totalPage}
            onPageChange={props.changePage}
            containerClassName={"flex"}
            pageLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white dark:text-gray-400 dark:bg-gray-700"
            }
            previousLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            nextLinkClassName={
              " text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            breakClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            activeLinkClassName={
              "active-paginate dark:text-white dark:bg-gray-700"
            }
            disabledLinkClassName={
              "disable-paginate hover:bg-gray-300 hover:text-gray-500"
            }
          />
        </nav>
      </div>
    </div>
  );
};

export default TableAdmin;

import Cookies from "js-cookie";
import React, { useState } from "react";

import InvoiceService from "../../app/Services/InvoiceService";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import AuthApi from "../../AuthApi";
import ToastNoify from "../../utils/ToastNotify";
import LoadingSpinner from "../template/LoadingSpinner";
import ModalAddTagihan from "./ModalAddTagihan";
import ModalDetailPiutang from "./ModalDetailPiutang";
import ModalEditTagihan from "./ModalEditTagihan";
import TableTagihan from "./TableTagihan";

const MainTagihan = (props) => {
  const invoiceService = new InvoiceService();
  const Auth = React.useContext(AuthApi);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  // Modal add tagihan
  const [modalAdd, setModalAdd] = useState(false);

  // Modal edit tagihan
  const [modalDetail, setModalDetail] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [dataEditTagihan, setDataEditTagihan] = useState({});
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const changePage = ({ selected }) => {
    setPage(selected + 1);
  };

  async function addInvoice(payload) {
    const accessToken = await RefreshTokenService();
    if (accessToken === "fail") {
      Auth.setAuth(false);
      Cookies.remove("refreshToken");
      Cookies.remove("user");
    }

    const result = await invoiceService.postInvoices(payload, accessToken);

    setModalAdd(false);

    if (result.status === "success") {
      // getPiutang();
      ToastNoify("success", "Berhasil menambahkan invoice");
    } else {
      setLoading(false);
      ToastNoify("error", result.message);
    }
  }

  async function putStatusInvoices(payload) {
    setLoading(true);
    const accessToken = await RefreshTokenService();
    if (accessToken === "fail") {
      Auth.setAuth(false);
      Cookies.remove("refreshToken");
      Cookies.remove("user");
    }
    payload.accessToken = accessToken;

    const result = await invoiceService.putStatusInvoices(payload);

    if (result.status === "success") {
      ToastNoify("success", result.message);
      // getPiutang();
    } else {
      ToastNoify("error", result.message);
      setLoading(false);
    }
  }

  async function deleteInvoices(payload) {
    setLoading(true);
    const accessToken = await RefreshTokenService();
    if (accessToken === "fail") {
      Auth.setAuth(false);
      Cookies.remove("refreshToken");
      Cookies.remove("user");
    }
    payload.accessToken = accessToken;
    console.log("ini payload", payload);

    const result = await invoiceService.deleteInvoices(payload);

    if (result.status === "success") {
      ToastNoify("success", result.message);
      // getPiutang();
    } else {
      ToastNoify("error", result.message);
      setLoading(false);
    }
  }

  async function showModalEdit(id) {
    setLoading(true);
    const accessToken = await RefreshTokenService();
    if (accessToken === "fail") {
      Auth.setAuth(false);
      Cookies.remove("refreshToken");
      Cookies.remove("user");
    }

    const result = await invoiceService.getInvoiceById({ id, accessToken });
    if (result.status === "success") {
      console.log("init reuslts ", result.data.invoice);
      setDataEditTagihan(result.data.invoice);
      setModalEdit(true);
      setLoading(false);
    } else {
      ToastNoify("error", result.message);
      setLoading(false);
    }
  }

  function offModalEdit() {
    setModalEdit(!modalEdit);
    setDataEditTagihan({});
  }

  return (
    <main className="h-full overflow-y-auto">
      {isLoading && <LoadingSpinner />}
      <TableTagihan
        page={page}
        changePage={changePage}
        putStatusInvoices={putStatusInvoices}
        deleteInvoices={deleteInvoices}
        showModalEdit={showModalEdit}
        setModalAdd={setModalAdd}
        setModalDetail={setModalDetail}
        modalDetail={modalDetail}
        dataPiutang={props.dataPiutang}
        getPiutang={props.getPiutang}
      />
      <ModalDetailPiutang
        modalDetail={modalDetail}
        setModalDetail={setModalDetail}
      />
      <ModalEditTagihan
        modalEdit={modalEdit}
        offModalEdit={offModalEdit}
        dataEditTagihan={dataEditTagihan}
      />
      <ModalAddTagihan
        modalAdd={modalAdd}
        setModalAdd={setModalAdd}
        setLoading={setLoading}
        addInvoice={addInvoice}
      />
    </main>
  );
};

export default MainTagihan;

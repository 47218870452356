import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useState } from "react";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import ContentSidebar from "./ContentSidebar";
import SidebarMobile from "./SidebarMobile";

function Sidebar(props) {
  const [dataPKPU, setDataPKPU] = useState([]);
  useEffect(() => {
    getDataLawsuit();
  }, []);
  const getDataLawsuit = async (props) => {
    try {
      const token = await RefreshTokenService();
      const res = await axios({
        method: "GET",
        url: `https://seributech.com/apipkpu/lawsuit/active`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDataPKPU(res.data.data.lawsuits);
      Cookies.set("lawsuits", JSON.stringify(res.data.data.lawsuits), {
        expires: 7,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (props.dataPerkara) {
      setDataPKPU(props.dataPerkara);
    }
  }, [props.dataPerkara]);

  return (
    <div>
      {/* Desktop sidebar */}
      <aside className="z-20 hidden lg:block h-full w-64 overflow-y-auto bg-white dark:bg-gray-800 flex-shrink-0">
        <ContentSidebar dataPKPU={dataPKPU} setDataPKPU={setDataPKPU} />
      </aside>
      {/* Desktop sidebar end */}

      {/* Mobile Sidebar */}
      <SidebarMobile isSideMenuOpen={props.isSideMenuOpen} />
      {/* Mobile Sidebar End */}
    </div>
  );
}

export default Sidebar;

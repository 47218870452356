function MainForms() {
  return (
    <main className='h-full overflow-y-auto'>
      <div className='px-6 mx-auto'>
        <h1>Form</h1>
      </div>
    </main>
  )
}

export default MainForms

import { Navigate, Outlet } from "react-router-dom"

const ProtectRouteAuth = (props) => {
  const auth = props.auth
  const accessAuthorizationUser = props.accessAuthorizationUser
  const roleAllowed = props.role

  if (!auth) {
    return <Navigate to='/'></Navigate>
  } else {
    if (roleAllowed) {
      return <Outlet></Outlet>
      // if (
      //   accessAuthorizationUser?.find((role) => roleAllowed?.includes(role))
      // ) {
      //   return <Outlet />
      // } else {
      //   return (
      //     <Navigate
      //       to='/dashboard'
      //       state={{ message: "You Unathorized" }}></Navigate>
      //   )
      // }
    } else {
      return <Outlet />
    }
  }
}

export default ProtectRouteAuth

import axios from "axios";
import API_ENDPOINT from "../Config/API_ENDPOINT";

class CreditorService {
  constructor() {
    this._axios = axios;
    this._endpoint = API_ENDPOINT;
  }

  async getUsers(payload) {
    try {
      const response = await this._axios({
        method: "GET",
        url: `${this._endpoint.CREDITOR}`,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }
  async getCreditorWaiting(payload) {
    try {
      const response = await this._axios({
        method: "GET",
        url: `${this._endpoint.CREDITOR}/lawsuit/${payload.lawsuitId}?page=1&limit=5&status=waiting-confirmation`,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async getUserById(payload) {
    try {
      const response = await this._axios({
        method: "GET",
        url: `${this._endpoint.CREDITOR}/${payload.id}`,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export default CreditorService;

import { Form, Input, message, Modal } from "antd";
import axios from "axios";
import React from "react";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";

const ModalAddAdmin = (props) => {
  const [form] = Form.useForm();
  const onFinish = async (value) => {
    try {
      const payload = {
        name: value.name,
        email: value.email,
        password: value.password,
      };
      const accessToken = await RefreshTokenService();
      const res = await axios({
        method: "POST",
        url: `${API_ENDPOINT.USER}/admin`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: payload,
      });
      console.log("ini config", res.config);
      if (res.status === 200) {
        props.setModalAdd(false);
        message.success(res.data.message);
        props.getDataAdmin();
      } else {
        message.success(res.data.message);
      }
    } catch (error) {
      message.success("gagal");
    }
  };
  return (
    <Modal
      title="Tambah Admin"
      footer={null}
      onOk={() => props.setModalAdd(false)}
      onCancel={() => {
        props.setModalAdd(false);
      }}
      open={props.modalAdd}
    >
      <Form className="mt-3" form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              required: true,
              message: "Nama wajib diisi",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Email wajib diisi",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password wajib diisi",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item style={{ display: "flex", justifyContent: "center" }}>
          <button className="ant-btn ant-btn-primary mt-5 bg-purple-600 hover:bg-purple-700 py-2 px-5 rounded-md text-white ">
            Tambah Admin
          </button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddAdmin;

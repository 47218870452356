import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import "./index.css";
import AuthApi from "./AuthApi";
import React, { useEffect, useState } from "react";
import Authentication from "./pages/Authentication";
import ProtectRouteAuthIsLogin from "./Midleware/ProtectRouteAuthIsLogin";
import ProtectRouteAuth from "./Midleware/ProtectRouteAuth";
import Cookies from "js-cookie";
import Forms from "./pages/Forms";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Authoization from "./app/Data/Authorization";
import AddInvoice from "./pages/Creditor/AddInvoice";
import Tagihan from "./pages/Creditor/Tagihan";
import Creditor from "./pages/Creditor/Creditor";
import Register from "./pages/Register/Register";
import Perkara from "./component/perkara/Perkara";
import Admin from "./component/admin/Admin";
import CreditorVerification from "./component/creditorverification/CreditorVerification";
import FormPengajuan from "./pages/Register/FormPengajuan";

function App() {
  const [auth, setAuth] = useState(true);
  const user = Cookies.get("user");
  let accessAuthorizationUser = "";
  if (user) {
    accessAuthorizationUser = JSON.parse(user);
    accessAuthorizationUser = accessAuthorizationUser.arz;
  }
  const [pageActive, setPageActive] = useState("dashboard");
  const readCookie = () => {
    const refreshToken = Cookies.get("refreshToken");
    if (!refreshToken) {
      setAuth(false);
    }
  };

  useEffect(() => {
    readCookie();
  }, []);

  return (
    <AuthApi.Provider value={{ auth, setAuth, pageActive, setPageActive }}>
      <ToastContainer />
      {/* <BrowserRouter basename='FeMongoose'> */}
      <BrowserRouter basename="/pkpu">
        <Routes>
          <Route element={<ProtectRouteAuthIsLogin auth={auth} />}>
            <Route index element={<Authentication />} />
            {/* <Route path="/register" element={<Register />} exact /> */}
            <Route path="/register/:perkara" element={<Register />} />
            <Route path="/register/upload" element={<FormPengajuan />} />
          </Route>

          <Route element={<ProtectRouteAuth auth={auth} />}>
            <Route path="/menambahkan-tagihan" element={<AddInvoice />} />
            <Route
              path="/verifikasi-kreditor"
              element={<CreditorVerification />}
            />
            <Route
              path="/verifikasi-kreditor/:lawsuitId"
              element={<CreditorVerification />}
            />
            <Route path="/admin" element={<Admin />} />
            <Route path="/tagihan" element={<Tagihan />} />
            <Route path="/tagihan/:lawsuitId" element={<Tagihan />} />
            <Route path="/creditor" element={<Creditor />} />
            <Route path="/creditor/:lawsuitId" element={<Creditor />} />
            <Route path="/perkara" element={<Perkara />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>

          <Route
            element={
              <ProtectRouteAuth
                auth={auth}
                role={Authoization.FORMS}
                accessAuthorizationUser={accessAuthorizationUser}
              />
            }
          >
            <Route path="/forms" element={<Forms />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthApi.Provider>
  );
}

export default App;

import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import compressImage from "../../utils/CompressImage";
import AuthApi from "../../AuthApi";

const ModalEditTagihanRegister = (props) => {
  const [dataInitial, setDataInitial] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(dataInitial);
  }, [dataInitial, form]);

  useEffect(() => {
    setDataInitial(props.dataModal);
  }, [props.modalDetail]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [isLoading, setLoading] = useState(false);
  const Auth = React.useContext(AuthApi);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  // menyimpan list file gambar
  const [listRekap, setListRekap] = useState([]);
  const [listDasarTagihan, setListDasarTagihan] = useState([]);
  const [listBuktiPembayaran, setListBuktiPembayaran] = useState([]);
  // menyimpan gambar
  const [rekap, setRekap] = useState(null);
  const [dasarTagihan, setDasarTagihan] = useState(null);
  const [buktiPembayaran, setBuktiPembayaran] = useState(null);
  //properti gambar
  const propsRekap = {
    onRemove: (file) => {
      const index = listRekap.indexOf(file);
      const newFileList = listRekap.slice();
      newFileList.splice(index, 1);
      setListRekap([]);
      setRekap(null);
    },
    beforeUpload: async (file) => {
      // setListRekap([...listRekap, await compressImage(file)]);
      setRekap(await compressImage(file));

      return false;
    },
    listRekap,
    multiple: false,
  };

  const propsDasarTagihan = {
    onRemove: (file) => {
      const index = listDasarTagihan.indexOf(file);
      const newFileList = listDasarTagihan.slice();
      newFileList.splice(index, 1);
      setListDasarTagihan(newFileList);
      setDasarTagihan(null);
    },
    beforeUpload: async (file) => {
      setListDasarTagihan([...listDasarTagihan, await compressImage(file)]);
      setDasarTagihan(await compressImage(file));

      return false;
    },
    listDasarTagihan,
  };

  const propsBuktiPembayaran = {
    onRemove: (file) => {
      const index = listBuktiPembayaran.indexOf(file);
      const newFileList = listBuktiPembayaran.slice();
      newFileList.splice(index, 1);
      setListBuktiPembayaran(newFileList);
      setBuktiPembayaran(null);
    },
    beforeUpload: async (file) => {
      setListBuktiPembayaran([
        ...listBuktiPembayaran,
        await compressImage(file),
      ]);
      setRekap(await compressImage(file));

      return false;
    },
    listBuktiPembayaran,
  };

  //on change gambar
  const onChangedRekap = async (info) => {
    setRekap(await compressImage(info.file));
  };
  const onChangedDasarTagihan = async (info) => {
    setDasarTagihan(await compressImage(info.file));
  };
  const onChangedBuktiPembayaran = async (info) => {
    setBuktiPembayaran(await compressImage(info.file));
  };

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  function toggleSideMenu() {
    const hamburger = document.getElementById("humbergerButton");
    hamburger.classList.toggle("hamburger-active");
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  const onFinish = async (values) => {
    message.success("Berhasil edit tagihan");
    // props.setTagihan((prevArray) => [...prevArray, values]);
    const updatedTagihan = [...props.tagihan]; // create a new copy of the tagihan array

    // get the previous state for the specified index
    const prevTagihan = updatedTagihan[props.indexDetail];

    // merge the previous state with the updated values using the spread operator
    const newTagihan = {
      ...prevTagihan,
      ...values,
      billRecapitulation:
        rekap !== null // check if rekap is not null
          ? rekap // if it is not null, use it as the new billRecapitulation value
          : prevTagihan.hasOwnProperty("billRecapitulation") &&
            prevTagihan.billRecapitulation !== undefined &&
            prevTagihan.billRecapitulation !== null
          ? prevTagihan.billRecapitulation
          : null,
      proofOfPayment:
        buktiPembayaran !== null
          ? buktiPembayaran
          : prevTagihan.hasOwnProperty("proofOfPayment") &&
            prevTagihan.proofOfPayment !== undefined &&
            prevTagihan.proofOfPayment !== null
          ? prevTagihan.proofOfPayment
          : null,
      fundamentalBill:
        dasarTagihan !== null
          ? dasarTagihan
          : prevTagihan.hasOwnProperty("fundamentalBill") &&
            prevTagihan.fundamentalBill !== undefined &&
            prevTagihan.fundamentalBill !== null
          ? prevTagihan.fundamentalBill
          : null, /// / add the fundamentalBill from the previous state, or set it to null if it is undefined or null
    };

    // update the element at the specified index with the new value
    updatedTagihan[props.indexDetail] = newTagihan;

    // update the state with the new tagihan array
    props.setTagihan(updatedTagihan);

    console.log("tagihan terupdate", props.tagihan);
    form.resetFields();
    console.log("tagihan", props.tagihan);
    setListRekap([]);
    setListDasarTagihan([]);
    setListBuktiPembayaran([]);
    closeModal();
    // setLoading(true);
    // const formData = new FormData();
    // formData.append("invoiceNumber", values.invoiceNumber);
    // formData.append("billNature", values.billNature);
    // formData.append("principalAmount", values.principalAmount.toString());
    // formData.append("interest", values.interest.toString());
    // formData.append("amercement", values.amercement.toString());
    // if (values.fundamentalBill != null) {
    //   formData.append("fundamentalBill", dasarTagihan);
    // }
    // if (values.billRecapitulation != null) {
    //   formData.append("billRecapitulation", rekap);
    // }
    // if (values.proofOfPayment != null) {
    //   formData.append("proofOfPayment", buktiPembayaran);
    // }
    // var data = JSON.stringify({
    //   refreshToken: `${Cookies.get("refreshToken")}`,
    // });
    // // You can use any AJAX library you like
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    // try {
    //   let accessToken;
    //   var configuration = {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   };
    //   const dataToken = await axios.put(
    //     `${CONFIG.BASE_URL}/authentication`,
    //     data,
    //     configuration
    //   );
    //   accessToken = dataToken.data.data.accessToken;
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   };
    //   let response = await axios.post(
    //     `${CONFIG.BASE_URL}/invoices`,
    //     formData,
    //     config
    //   );
    //   response = response.data;
    //   setLoading(false);
    //   if (response.status === "success") {
    //     ToastNoify("success", response.message);
    //     form.resetFields();
    //   } else {
    //     ToastNoify("error", response.message);
    //   }
    // } catch (error) {
    //   console.error(error);
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    Auth.setPageActive("menambahkan-tagihan");
  });
  function closeModal() {
    setRekap(null);
    setDasarTagihan(null);
    setBuktiPembayaran(null);
    form.resetFields();
    props.setModalDetail(false);
  }

  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const parser = (value) => value.replace(/\Rp.\s?|(,*)/g, "");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  return (
    <Modal
      title="Modal Title"
      open={props.modalDetail}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
      ]}
    >
      {props.modalDetail && (
        <Form
          initialValues={props.tagihan[props.dataDetail]}
          form={form}
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          scrollToFirstError
          // style={{
          //   maxWidth: "w-3/5",
          // }}
        >
          <Form.Item
            label={
              <Tooltip title="Checklist jika menggunakan kuasa hukum">
                Sifat Tagihan
              </Tooltip>
            }
            name="billNature"
            initialValue={"konkuren"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Select.Option value="konkuren">Kreditor Konkuren</Select.Option>
              <Select.Option value="preferen">Kreditor Preferen</Select.Option>
              <Select.Option value="separatis">
                Kreditor Separatis
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={"invoiceNumber"}
            label="No Tagihan"
            rules={[
              {
                required: true,
                message: "No tagihan wajib di isi",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="principalAmount" label="Tagihan Pokok">
            <InputNumber
              formatter={formatter}
              parser={parser}
              className="w-full"
            />
          </Form.Item>
          <Form.Item label="Bunga" name="interest">
            <InputNumber
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>
          <Form.Item name="amercement" label="Denda">
            <InputNumber
              formatter={formatter}
              parser={parser}
              className="w-full"
            />
          </Form.Item>
          <div className="flex flex-wrap justify-between">
            {props.tagihan[props.indexDetail] !== null &&
            props.tagihan[props.indexDetail].hasOwnProperty(
              "billRecapitulation"
            ) &&
            props.tagihan[props.indexDetail].billRecapitulation !== undefined &&
            props.tagihan[props.indexDetail].billRecapitulation !== null ? (
              <div>
                <h2 className="flex flex-cols mb-3">
                  Rekapitulasi Tagihan
                  <span></span>
                </h2>
                <Image
                  className="border border-purple rounded-md"
                  height={100}
                  width={100}
                  src={URL.createObjectURL(
                    props.tagihan[props.indexDetail].billRecapitulation
                  )}
                ></Image>
                <button
                  type="button"
                  onClick={() => {
                    const updatedTagihan = [...props.tagihan];
                    const objectToDelete = updatedTagihan[props.indexDetail];
                    delete objectToDelete.billRecapitulation;
                    updatedTagihan[props.indexDetail] = objectToDelete;

                    props.setTagihan(updatedTagihan);
                  }}
                  className="text-red-700 block"
                >
                  Delete
                </button>
              </div>
            ) : (
              <Form.Item
                label="Rekapitulasi Tagihan"
                name={"billRecapitulation"}
              >
                <Upload
                  accept="image/*"
                  {...propsRekap}
                  onChange={onChangedRekap}
                  listType="picture-card"
                  onPreview={handlePreview}
                >
                  {rekap !== null ? null : (
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Select File
                      </div>
                    </div>
                  )}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Form.Item>
            )}
            {props.tagihan[props.indexDetail] !== null &&
            props.tagihan[props.indexDetail].hasOwnProperty(
              "fundamentalBill"
            ) &&
            props.tagihan[props.indexDetail].fundamentalBill !== undefined &&
            props.tagihan[props.indexDetail].fundamentalBill !== null ? (
              <div>
                <h2 className="flex flex-cols mb-3">
                  Dasar Tagihan
                  <span></span>
                </h2>
                <Image
                  className="border border-purple rounded-md"
                  height={100}
                  width={100}
                  src={URL.createObjectURL(
                    props.tagihan[props.indexDetail].fundamentalBill
                  )}
                ></Image>
                <button
                  type="button"
                  onClick={() => {
                    const updatedTagihan = [...props.tagihan];
                    const objectToDelete = updatedTagihan[props.indexDetail];
                    delete objectToDelete.fundamentalBill;
                    updatedTagihan[props.indexDetail] = objectToDelete;

                    props.setTagihan(updatedTagihan);
                  }}
                  className="text-red-700 block"
                >
                  Delete
                </button>
              </div>
            ) : (
              <Form.Item label="Dasar Tagihan" name={"fundamentalBill"}>
                <Upload
                  accept="image/*"
                  {...propsDasarTagihan}
                  onChange={onChangedDasarTagihan}
                  listType="picture-card"
                  onPreview={handlePreview}
                >
                  {dasarTagihan !== null ? null : (
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Select File
                      </div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            )}
            {props.tagihan[props.indexDetail] !== null &&
            props.tagihan[props.indexDetail].hasOwnProperty("proofOfPayment") &&
            props.tagihan[props.indexDetail].proofOfPayment !== null &&
            props.tagihan[props.indexDetail].proofOfPayment !== undefined ? (
              <div>
                <h2 className="flex flex-cols mb-3">
                  Dasar Tagihan
                  <span></span>
                </h2>
                <Image
                  className="border border-purple rounded-md"
                  height={100}
                  width={100}
                  src={URL.createObjectURL(
                    props.tagihan[props.indexDetail].proofOfPayment
                  )}
                ></Image>
                <button
                  type="button"
                  onClick={() => {
                    const updatedTagihan = [...props.tagihan];
                    const objectToDelete = updatedTagihan[props.indexDetail];
                    delete objectToDelete.proofOfPayment;
                    updatedTagihan[props.indexDetail] = objectToDelete;

                    props.setTagihan(updatedTagihan);
                  }}
                  className="text-red-700 block"
                >
                  Delete
                </button>
              </div>
            ) : (
              <Form.Item label="Bukti Pembayaran" name={"proofOfPayment"}>
                <Upload
                  accept="image/*"
                  {...propsBuktiPembayaran}
                  onChange={onChangedBuktiPembayaran}
                  listType="picture-card"
                  onPreview={handlePreview}
                >
                  {buktiPembayaran !== null ? null : (
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Select File
                      </div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            )}
          </div>
          <Form.Item style={{ display: "flex", justifyContent: "center" }}>
            <button
              type="submit"
              className="ant-btn ant-btn-primary mt-5 bg-purple-600 hover:bg-purple-700 py-2 px-5 rounded-md text-white "
            >
              Simpan
            </button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default ModalEditTagihanRegister;

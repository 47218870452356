import { Image, message, Popconfirm, Select } from "antd";
import Search from "antd/es/input/Search";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import Empty from "../../assets/images/empty.jpeg";

const TableCreditorVerificationPerusahaan = (props) => {
  const items = [
    {
      key: "waiting-confirmation",
      label: "Waiting - Confirmation",
    },
    {
      key: "confirmed",
      label: "Confirmed",
    },
    {
      key: "non-active",
      label: "Non Active",
    },
  ];

  const [open, setOpen] = useState("");
  const [filter, setFilter] = useState("all");

  const handleMenuClick = async (data, id) => {
    const statusInvoice = data.key;
    await props.putStatusInvoices({ id, statusInvoice });
  };

  const handleDelete = async (creditorId) => {
    const token = await RefreshTokenService();
    const res = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
      url: `${API_ENDPOINT.CREDITOR}/${creditorId}`,
    });
    if (res.status === 200) {
      message.success("Berhasil Menghapus Kreditor");
      props.getCreditors();
    } else {
      message.error(res.data.message);
    }
  };
  const dataUser = Cookies.get("user");
  const user = dataUser ? JSON.parse(dataUser) : null;
  const approveCreditor = async (creditorId) => {
    const token = await RefreshTokenService();
    const payload = {
      creditorId: creditorId,
      status: "confirmed",
    };
    const res = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${API_ENDPOINT.CREDITOR}`,
      method: "PUT",
      data: payload,
    });
    if (res.status === 200) {
      message.success("data berhasil di update");
      setOpen("");
      props.getCreditors();
    } else {
      message.error(res.data.message);
    }
  };
  return (
    <div className="m-4 max-w-full]">
      <div className="w-full overflow-hidden rounded-lg shadow-xs border">
        <div className="w-full overflow-x-scroll">
          <h1 className="space-x-2 m-4 text-primary">Badan Usaha</h1>
          <div className="flex">
            <Search
              className="m-4 bg-primary text-primary rounded-md hover:bg-primary"
              style={{
                width: 300,
              }}
              placeholder="Cari Kreditor"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={(value) => console.log(value)}
            />
            <Select
              className="m-4"
              defaultValue={filter}
              style={{
                width: 140,
              }}
              size="large"
              onChange={(value) => setFilter(value)}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "tipeKreditor",
                  label: "Tipe Kreditor",
                },
                {
                  value: "nama",
                  label: "Nama",
                },
                {
                  value: "alamat",
                  label: "Alamat",
                },
                {
                  value: "nomorTelepon",
                  label: "Nomor Telepon",
                },
                {
                  value: "email",
                  label: "Email",
                },
              ]}
            />
          </div>

          <table className="w-full table-auto whitespace-no-wrap">
            <thead>
              <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                <th className="px-4 py-3 truncate">Aksi</th>

                <th className="px-4 py-3 truncate">Tipe Kreditor</th>
                <th className="px-4 py-3 min-w-[150px]">Nama</th>
                <th className="px-4 py-3 min-w-[150px]">Alamat</th>
                <th className="px-4 py-3 truncate">Nomor Telepon</th>
                <th className="px-4 py-3 truncate">Email</th>
                <th className="px-4 py-3 min-w-[150px]">Foto Akta</th>
                <th className="px-4 py-3 truncate">Foto Anggaran Dasar</th>
                <th className="px-4 py-3 truncate">Foto Dokumen Lainnya</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
              {props.creditors.map((creditor) => (
                <>
                  {creditor.creditor_type === "company" && (
                    <tr
                      // onClick={() => props.showModalDetail(creditor.creditorId)}
                      className="text-gray-700 dark:text-gray-400 hover:bg-purple-200"
                      key={creditor.creditor_id}
                    >
                      <td className="py-3">
                        <div className="flex items-center space-x-2 text-sm">
                          <Popconfirm
                            title="Pastikan data yang anda masukkan sudah sesuai"
                            description="Apakah data yang anda masukkan sudah sesuai?"
                            open={open === creditor.creditor_id}
                            onConfirm={() =>
                              approveCreditor(creditor.creditor_id)
                            }
                            onCancel={() => setOpen("")}
                            okButtonProps={{
                              style: {
                                background: "#6B46C1",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                                padding: "0px 15px",
                              },
                            }}
                          >
                            <button
                              onClick={(event) => {
                                // event.stopPropagation();
                                // props.showModalEdit(creditor.creditor_id);
                                setOpen(creditor.creditor_id);
                              }}
                              className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                              aria-label="Edit"
                            >
                              <svg
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                height="1em"
                                width="1em"
                                {...props}
                              >
                                <path d="M12.736 3.97a.733.733 0 011.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 01-1.065.02L3.217 8.384a.757.757 0 010-1.06.733.733 0 011.047 0l3.052 3.093 5.4-6.425a.247.247 0 01.02-.022z" />
                              </svg>
                              {/* <svg
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                                </svg> */}
                            </button>
                          </Popconfirm>

                          <button
                            onClick={() => {
                              handleDelete(creditor.creditor_id);
                            }}
                            className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                            aria-label="Delete"
                          >
                            <svg
                              className="w-5 h-5"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </td>
                      <td className="px-2 py-3 text-sm capitalize">
                        {creditor.creditor_type}
                      </td>
                      <td className="px-2 py-3 text-sm capitalize">
                        {creditor.name}
                      </td>
                      <td className="px-2 py-3 text-sm capitalize">
                        {creditor.address}
                      </td>
                      <td className="px-2 py-3 text-sm capitalize">
                        {creditor.phone_number}
                      </td>
                      <td className="px-2 py-3 text-sm capitalize">
                        {creditor.email}
                      </td>
                      <td className="px-2 py-3 text-sm capitalize">
                        <Image
                          alt=""
                          src={Empty}
                          height="10"
                          width="10"
                          className="w-[50px] h-[50px]"
                        />
                      </td>
                      <td className="px-2 py-3 text-sm capitalize">
                        <Image
                          alt=""
                          src={Empty}
                          height="10"
                          width="10"
                          className="w-[50px] h-[50px]"
                        />
                      </td>
                      <td className="px-2 py-3 text-sm capitalize">
                        <Image
                          alt=""
                          src={Empty}
                          height="10"
                          width="10"
                          className="w-[50px] h-[50px]"
                        />
                      </td>

                      {/* <td className="px-2 py-3 text-sm capitalize">
                        {creditor.tipeKreditor}
                      </td>
                      <td className="px-2">
                        {user?.role !== "user" && (
                          <Dropdown
                            menu={{
                              items,
                              onClick: (e) =>
                                handleMenuClick(e, creditor.invoiceId),
                              selectable: true,
                              defaultSelectedKeys: creditor.statusCreditor,
                            }}
                          >
                            <Typography.Link className="flex items-center justify-between gap-2">
                              <>
                                {(() => {
                                  switch (creditor.statusCreditor) {
                                    case "waiting-confirmation":
                                      return (
                                        <span className="px-2 py-1 font-semibold leading-tight  text-blue-700 bg-blue-100 rounded-md capitalize">
                                          {creditor.statusCreditor}
                                        </span>
                                      );
                                    case "confirmed":
                                      return (
                                        <span className="px-2 py-1 font-semibold leading-tight  text-green-700 bg-green-100 rounded-md capitalize">
                                          {creditor.statusCreditor}
                                        </span>
                                      );
                                    default:
                                      return (
                                        <span className="px-2 py-1 font-semibold leading-tight  text-red-700 bg-red-100 rounded-md capitalize">
                                          {creditor.statusCreditor}
                                        </span>
                                      );
                                  }
                                })()}
                              </>
                              <AiFillCaretDown className=" text-purple-600 w-[20px]"></AiFillCaretDown>
                            </Typography.Link>
                          </Dropdown>
                        )}
                      </td> */}
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-wrap justify-between px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
        <p className="flex items-center col-span-3">
          Total Data : {props.totalData}
          <br /> Halaman : {props.page} Dari {props.totalPage}
        </p>
        <nav
          key={props.totalData}
          role="navigation"
          className="col-span-4 mt-2 sm:mt-4 sm:justify-end"
        >
          <ReactPaginate
            className="flex flex-wrap justify-center"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={props.totalPage}
            onPageChange={props.changePage}
            containerClassName={"flex"}
            pageLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white dark:text-gray-400 dark:bg-gray-700"
            }
            previousLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            nextLinkClassName={
              " text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            breakClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            activeLinkClassName={
              "active-paginate dark:text-white dark:bg-gray-700"
            }
            disabledLinkClassName={
              "disable-paginate hover:bg-gray-300 hover:text-gray-500"
            }
          />
        </nav>
      </div>
    </div>
  );
};

export default TableCreditorVerificationPerusahaan;

import React from "react";
import "./pdf.css";

const TablePdf = React.forwardRef((props, ref) => {
  return (
    <div className="p-4 bg-white">
      <div className="text-center mb-4">
        <h1 className="font-bold text-xl">Header Title Here</h1>
        <p>Additional header information can be placed here.</p>
      </div>
      <table
        className="table-auto w-full border-collapse border border-black p-4"
        ref={ref}
      >
        <thead>
          <tr>
            <th className="border border-gray-500 px-4 py-2">Nama Kreditor</th>
            <th className="border border-gray-500 px-4 py-2">Dasar Tagihan</th>
            <th className="border border-gray-500 px-4 py-2">
              Alamat Pokok (Rp)
            </th>
            <th className="border border-gray-500 px-4 py-2">Bunga ( Rp)</th>
            <th className="border border-gray-500 px-4 py-2">Denda (Rp)</th>
            <th className="border border-gray-500 px-4 py-2">Lain-lain (Rp)</th>
            <th className="border border-gray-500 px-4 py-2">Total (Rp)</th>
            <th className="border border-gray-500 px-4 py-2">
              Total Tagihan Verifikasi
            </th>
            <th className="border border-gray-500 px-4 py-2">Keterangan</th>
          </tr>
        </thead>
        <tbody>
          {props.creditors?.map((creditor, index) => (
            <tr key={index}>
              <td className="border border-gray-500 px-4 py-2">
                {creditor.name}
              </td>
              <td className="border border-gray-500 px-4 py-2">
                {creditor.email}
              </td>
              <td className="border border-gray-500 px-4 py-2">
                {creditor.phoneNumber}
              </td>
              <td className="border border-gray-500 px-4 py-2">1000000</td>
              <td className="border border-gray-500 px-4 py-2">0</td>
              <td className="border border-gray-500 px-4 py-2">0</td>
              <td className="border border-gray-500 px-4 py-2">6000000</td>
              <td className="border border-gray-500 px-4 py-2">5500000</td>
              <td className="border border-gray-500 px-4 py-2"></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default TablePdf;

import { Dropdown, Image, message, Select, Typography } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { FcApproval } from "react-icons/fc";
import { MdOutlineLibraryAdd } from "react-icons/md";
import ReactPaginate from "react-paginate";
import FormatRupiah from "../../utils/FormatRupiah";
import KTP from "../../assets/images/150067.jpg";
import Search from "antd/es/input/Search";
import { useState } from "react";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";
import axios from "axios";
import RefreshTokenService from "../../app/Services/RefreshTokenService";

const TableCreditor = (props) => {
  const items = [
    {
      key: "waiting-confirmation",
      label: "Waiting - Confirmation",
    },
    {
      key: "confirmed",
      label: "Confirmed",
    },
    {
      key: "non-active",
      label: "Non Active",
    },
  ];
  const [filter, setFilter] = useState("all");

  const handleMenuClick = async (data, id) => {
    const statusInvoice = data.key;
    await props.putStatusInvoices({ id, statusInvoice });
  };
  const handleDelete = async (creditorId) => {
    const token = await RefreshTokenService();
    const res = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
      url: `${API_ENDPOINT.CREDITOR}/${creditorId}`,
    });
    if (res.status === 200) {
      message.success("Berhasil Menghapus Kreditor");
      props.getCreditors();
    } else {
      message.error(res.data.message);
    }
  };

  const dataUser = Cookies.get("user");
  const user = dataUser ? JSON.parse(dataUser) : null;
  return (
    <div className="m-4 max-w-full]">
      <div className="w-full overflow-hidden rounded-lg shadow-xs border">
        <div className="w-full overflow-x-scroll">
          <h1 className="space-x-2 m-4 text-primary">Perorangan</h1>
          <div className="flex">
            <Search
              className="m-4 bg-primary text-primary rounded-md hover:bg-primary"
              style={{
                width: 300,
              }}
              placeholder="Cari Kreditor"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={(value) => console.log(value)}
            />
            <Select
              className="m-4"
              defaultValue={filter}
              style={{
                width: 140,
              }}
              size="large"
              onChange={(value) => setFilter(value)}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "tipeKreditor",
                  label: "Tipe Kreditor",
                },
                {
                  value: "nama",
                  label: "Nama",
                },
                {
                  value: "alamat",
                  label: "Alamat",
                },
                {
                  value: "nomorTelepon",
                  label: "Nomor Telepon",
                },
                {
                  value: "email",
                  label: "Email",
                },
              ]}
            />
            <button
              onClick={() => props.setModalAdd(true)}
              className="space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
            >
              <MdOutlineLibraryAdd className="w-[20px] h-[20px]" />
              <span className="font-semibold">Menambahkan Kreditor</span>
            </button>
          </div>

          <table className="w-full table-auto whitespace-no-wrap">
            <thead>
              <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                <th className="px-4 py-3 truncate">Aksi</th>

                <th className="px-4 py-3 truncate">Tipe Kreditor</th>
                <th className="px-4 py-3 min-w-[150px]">Nama</th>
                <th className="px-4 py-3 min-w-[150px]">Alamat</th>
                <th className="px-4 py-3 truncate">Nomor Telepon</th>
                <th className="px-4 py-3 truncate">Email</th>
                <th className="px-4 py-3 truncate">NIK KTP</th>
                <th className="px-4 py-3 truncate">Foto KTP</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 border">
              {props.dataCreditor &&
                props.dataCreditor.map((creditor, index) => (
                  <React.Fragment key={index}>
                    {creditor.creditor_type === "individual" && (
                      <tr
                        onClick={() => props.handleRowClick(creditor)}
                        className="text-gray-700 dark:text-gray-400 hover:bg-purple-200"
                        key={creditor.creditor_id}
                      >
                        <td className="py-3">
                          <div className="flex items-center space-x-2 text-sm">
                            <button
                              onClick={() => {
                                handleDelete(creditor.creditor_id);
                              }}
                              className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                              aria-label="Delete"
                            >
                              <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </td>
                        <td className="px-2 py-3 text-sm capitalize">
                          {creditor.creditor_type}
                        </td>
                        <td className="px-2 py-3 text-sm capitalize">
                          {creditor.name}
                        </td>
                        <td className="px-2 py-3 text-sm capitalize">
                          {creditor.address}
                        </td>
                        <td className="px-2 py-3 text-sm capitalize">
                          {creditor.phone_number}
                        </td>
                        <td className="px-2 py-3 text-sm capitalize">
                          {creditor.email}
                        </td>
                        <td className="px-2 py-3 text-sm capitalize">
                          {creditor.nikKTP}
                        </td>
                        <td className="px-2 py-3 text-sm capitalize">
                          <Image alt="" src={KTP} />
                        </td>

                        {/* <td className="px-2 py-3 text-sm capitalize">
                    {creditor.tipeKreditor}
                  </td>
                  <td className="px-2">
                    {user?.role !== "user" && (
                      <Dropdown
                        menu={{
                          items,
                          onClick: (e) =>
                            handleMenuClick(e, creditor.invoiceId),
                          selectable: true,
                          defaultSelectedKeys: creditor.statusCreditor,
                        }}
                      >
                        <Typography.Link className="flex items-center justify-between gap-2">
                          <>
                            {(() => {
                              switch (creditor.statusCreditor) {
                                case "waiting-confirmation":
                                  return (
                                    <span className="px-2 py-1 font-semibold leading-tight  text-blue-700 bg-blue-100 rounded-md capitalize">
                                      {creditor.statusCreditor}
                                    </span>
                                  );
                                case "confirmed":
                                  return (
                                    <span className="px-2 py-1 font-semibold leading-tight  text-green-700 bg-green-100 rounded-md capitalize">
                                      {creditor.statusCreditor}
                                    </span>
                                  );
                                default:
                                  return (
                                    <span className="px-2 py-1 font-semibold leading-tight  text-red-700 bg-red-100 rounded-md capitalize">
                                      {creditor.statusCreditor}
                                    </span>
                                  );
                              }
                            })()}
                          </>
                          <AiFillCaretDown className=" text-purple-600 w-[20px]"></AiFillCaretDown>
                        </Typography.Link>
                      </Dropdown>
                    )}
                  </td> */}
                      </tr>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-wrap justify-between px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
        <p className="flex items-center col-span-3">
          Total Data : {props.totalData}
          <br /> Halaman : {props.page} Dari {props.totalPage}
        </p>
        <nav
          key={props.totalData}
          role="navigation"
          className="col-span-4 mt-2 sm:mt-4 sm:justify-end"
        >
          <ReactPaginate
            className="flex flex-wrap justify-center"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={props.totalPage}
            onPageChange={props.changePage}
            containerClassName={"flex"}
            pageLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white dark:text-gray-400 dark:bg-gray-700"
            }
            previousLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            nextLinkClassName={
              " text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            breakClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            activeLinkClassName={
              "active-paginate dark:text-white dark:bg-gray-700"
            }
            disabledLinkClassName={
              "disable-paginate hover:bg-gray-300 hover:text-gray-500"
            }
          />
        </nav>
      </div>
    </div>
  );
};

export default TableCreditor;

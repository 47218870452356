import axios from "axios";
import API_ENDPOINT from "../Config/API_ENDPOINT";

class InvoiceService {
  constructor() {
    this._axios = axios;
    this._endpoint = API_ENDPOINT;
  }

  async getInvoices(payload) {
    try {
      const response = await this._axios({
        method: "GET",
        url: `${this._endpoint.INVOICE}?page=${payload.page}&limit=${payload.limit}'`,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async getPiutang(payload) {
    try {
      const response = await this._axios({
        method: "GET",
        url: `${this._endpoint.PIUTANG}/lawsuit/${payload.lawsuitId}`,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async postInvoices(payload, accessToken) {
    try {
      const response = await this._axios({
        method: "POST",
        url: `${this._endpoint.INVOICE}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async putStatusInvoices(payload) {
    try {
      const data = JSON.stringify({
        invoiceId: payload.id,
        statusInvoice: payload.statusInvoice,
      });
      const response = await this._axios({
        method: "PUT",
        url: `${this._endpoint.INVOICE}/status`,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
          "Content-Type": "application/json",
        },
        data: data,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async deleteInvoices(payload) {
    try {
      const response = await this._axios({
        method: "DELETE",
        url: `${this._endpoint.INVOICE}/${payload.invoiceId}`,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async getInvoiceById(payload) {
    try {
      const response = await this._axios({
        method: "GET",
        url: `${this._endpoint.INVOICE}/${payload.id}`,
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export default InvoiceService;

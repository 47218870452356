import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import AuthApi from "../../AuthApi";
import Header from "../../component/template/Header";
import Sidebar from "../../component/template/Sidebar";
import LoadingSpinner from "../template/LoadingSpinner";
import MainPerkara from "./MainPerkara";

const Perkara = () => {
  const Auth = React.useContext(AuthApi);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPerkara, setDataPerkara] = useState([]);

  useEffect(() => {
    Auth.setPageActive("Buat Perkara");
  }, [Auth]);

  function toggleSideMenu() {
    setIsSideMenuOpen(!isSideMenuOpen);
  }
  useEffect(() => {
    getDataPerkara();
  }, []);

  const getDataPerkara = async () => {
    try {
      setIsLoading(true);
      const token = await RefreshTokenService();
      const res = await axios({
        url: `${API_ENDPOINT.LAWSUIT}/active`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      });
      setDataPerkara(res.data.data.lawsuits);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(error);
    }
  };

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar isSideMenuOpen={isSideMenuOpen} dataPerkara={dataPerkara} />
      <div className="flex flex-col flex-1 w-full overflow-auto">
        <Header toggleSideMenu={toggleSideMenu}></Header>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <MainPerkara
            getDataPerkara={getDataPerkara}
            dataPerkara={dataPerkara}
            setDataPerkara={setDataPerkara}
          />
        )}
      </div>
    </div>
  );
};

export default Perkara;

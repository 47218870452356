import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import React from "react";
import axios from "axios";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";
import RefreshTokenService from "../../app/Services/RefreshTokenService";

const ModalAddPerkara = (props) => {
  const onFinish = async (value) => {
    try {
      const payload = {
        name: value.name,
        type: value.statusPerkara,
        tanggalPutusan: value.date.format("YYYY-MM-DD"),
      };
      if (value.pn) {
        payload.pengadilanNegri = value.pn;
      }
      if (value.noPutusan) {
        payload.noPutusan = value.noPutusan;
      }
      if (value.kursAmerika) {
        payload.kursAmerica = value.kursAmerika;
      }
      if (value.kursSingapura) {
        payload.kursSingapore = value.kursSingapura;
      }
      if (value.kursEuro) {
        payload.kursEuro = value.kursEuro;
      }
      if (value.kursAustralia) {
        payload.kursAustralia = value.kursAustralia;
      }
      if (value.kursCina) {
        payload.kursChina = value.kursCina;
      }
      if (value.kursJepang) {
        payload.kursJepang = value.kursJepang;
      }
      if (value.kursHongkong) {
        payload.kursHongkong = value.kursHongkong;
      }
      if (value.kursKanada) {
        payload.kursKanada = value.kursKanada;
      }
      if (value.kursMalaysia) {
        payload.kursMalaysia = value.kursMalaysia;
      }
      const accessToken = await RefreshTokenService();
      const res = await axios({
        method: "POST",
        url: API_ENDPOINT.LAWSUIT,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: payload,
      });
      if (res.status === 200) {
        props.setIsModalOpen(false);
        props.getDataPerkara();
      } else {
      }
      form.resetFields();
    } catch (error) {
      form.resetFields();
    }
  };
  async function handleCancel() {
    props.setIsModalOpen(false);
  }
  async function handleOk() {}
  const [form] = Form.useForm();
  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const parser = (value) => value.replace(/Rp.\s?|(,*)/g, "");
  return (
    <Modal
      title="Tambah Perkara"
      open={props.isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Nama Klien"
          rules={[
            {
              required: true,
              message: "Nama wajib di isi",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="pn" label="Tempat Pengadilan Niaga">
          <Input />
        </Form.Item>
        <Form.Item name="noPutusan" label="Nomor Putusan">
          <Input />
        </Form.Item>
        <Form.Item
          name="statusPerkara"
          label="Status Perkara"
          rules={[
            {
              required: true,
              message: "Status perkara wajib di isi",
            },
          ]}
        >
          <Select placeholder="Jenis Perkara" allowClear>
            <Select.Option value="PKPU">PKPU</Select.Option>
            <Select.Option value="Kepailitan">Kepailitan</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="date"
          label="Tanggal Keputusan"
          rules={[
            {
              required: true,
              message: "Tanggal keputusan wajib di isi",
            },
          ]}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select placeholder="Pilih status perkara" allowClear>
            <Select.Option value="active">Aktif</Select.Option>
            <Select.Option value="nonActive">Non Aktif</Select.Option>
          </Select>
        </Form.Item>
        <h2 className="font-bold">KURS</h2>
        <div className="flex gap-3">
          <div className="w-1/2">
            <Form.Item label="Kurs Dollar Amerika" name="kursAmerika">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Kurs Dollar Singapura" name="kursSingapura">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Kurs Euro" name="kursEuro">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Kurs Dollar Australia" name="kursAustralia">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Kurs Yuan Cina" name="kursCina">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Kurs Yuan Jepang" name="kursJepang">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Kurs Dollar Hongkong" name="kursHongkong">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Kurs Dollar Kanada" name="kursKanada">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Kurs Ringgit Malaysia" name="kursMalaysia">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item className="text-center">
          <Button
            htmlType="submit"
            type="submit"
            style={{
              background: "#6B46C1",
              color: "#FFFFFF",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddPerkara;

import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import LoadingSpinner from "../template/LoadingSpinner";
import ModalAddAdmin from "./ModalAddAdmin";
import TableAdmin from "./TableAdmin";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";

const MainAdmin = () => {
  const [isLoading, setLoading] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [dataAdmin, setDataAdmin] = useState([]);

  useEffect(() => {
    getDataAdmin();
  }, []);

  async function getDataAdmin() {
    try {
      setLoading(true);
      const token = await RefreshTokenService();
      const res = await axios({
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        url: `${API_ENDPOINT.USER}/admin`,
      });
      // message.success(res.data.status);
      setDataAdmin(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }

  return (
    <main className="h-full overflow-y-auto">
      {isLoading && <LoadingSpinner />}
      <TableAdmin
        modalAdd={modalAdd}
        setModalAdd={setModalAdd}
        dataAdmin={dataAdmin}
      />
      <ModalAddAdmin
        modalAdd={modalAdd}
        setModalAdd={setModalAdd}
        getDataAdmin={getDataAdmin}
      />
    </main>
  );
};

export default MainAdmin;

import { Form, Input, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import React from "react";

const ModalDetailPiutang = (props) => {
  console.log("ini props dari modal", props);
  return (
    <Modal
      open={props.modalDetail}
      onCancel={() => props.setModalDetail(false)}
      title="Edit Piutang"
    >
      <Form>
        <FormItem label="Pokok">
          <Input />
        </FormItem>
        <FormItem label="Bunga">
          <Input />
        </FormItem>
        <FormItem label="Denda">
          <Input />
        </FormItem>
        <FormItem label="Biaya Lainnya">
          <Input />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ModalDetailPiutang;

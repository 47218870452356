import React from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

const ChartDashboard = () => {
  const dataChart = [
    {
      debitor: "Indosurya",
      data: {
        konkuren: 20,
        preferen: 5,
        separatis: 10,
      },
    },
    {
      debitor: "KSP SB",
      data: {
        konkuren: 20,
        preferen: 5,
        separatis: 10,
      },
    },
  ];

  const prepareData = () => {
    return dataChart.map((data, index) => {
      const { konkuren, preferen, separatis } = data.data;
      const COLORS = ["#8884d8", "#82ca9d", "#ffc658"];
      const chartData = [
        { name: "Konkuren", value: konkuren },
        { name: "Preferen", value: preferen },
        { name: "Separatis", value: separatis },
      ];

      return (
        <div key={index} className="pie">
          <h1 className="text-center text-gray-500 font-semibold">
            {data.debitor}
          </h1>
          <PieChart width={400} height={300}>
            <Pie
              dataKey="value"
              data={chartData}
              cx={200}
              cy={150}
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      );
    });
  };

  return <div className="w-full flex mt-7">{prepareData()}</div>;
};

export default ChartDashboard;

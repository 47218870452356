import Cookies from "js-cookie";
import jsPDF from "jspdf";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import CreditorService from "../../app/Services/CreditorService";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import AuthApi from "../../AuthApi";
import ToastNoify from "../../utils/ToastNotify";
import LoadingSpinner from "../template/LoadingSpinner";
import TableCreditorVerification from "./TableCreditorVerification";
import TableCreditorVerificationPerusahaan from "./TableCreditorVerificationPerusahaan";

const MainCreditorVerification = (props) => {
  const creditorService = new CreditorService();
  const Auth = React.useContext(AuthApi);
  const [isLoading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [detailCreditor, setDetailCreditor] = useState([]);
  const limit = 10;

  // Modal add tagihan
  const [modalAdd, setModalAdd] = useState(false);

  // Modal edit tagihan
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [dataEditTagihan, setDataEditTagihan] = useState({});
  const params = useParams();

  const changePage = ({ selected }) => {
    setPage(selected + 1);
  };
  async function showModalEdit() {
    setModalEdit(true);
  }

  async function showModalDetail(id) {
    setLoading(true);
    const accessToken = await RefreshTokenService();
    if (accessToken === "fail") {
      Auth.setAuth(false);
      Cookies.remove("refreshToken");
      Cookies.remove("user");
      console.log("terpanggil");
    }

    const result = await creditorService.getUserById({ id, accessToken });
    console.log("status", result.status);
    if (result.status === "success") {
      console.log("init result ", result.data);
      setModalDetail(true);
      setLoading(false);
      setDetailCreditor(result.data);
    } else {
      ToastNoify("error", result.message);
      setLoading(false);
    }
  }

  function offModalEdit() {
    setModalEdit(!modalEdit);
    setDataEditTagihan({});
  }
  function offModalDetail() {
    setModalDetail(!modalDetail);
  }

  const componentRef = useRef();

  const handleSave = () => {
    // const pdf = new jsPDF("potrait", "pt", "a3");
    // const page = componentRef.current;
    // const pageWidth = pdf.internal.pageSize.width - 20;
    // const fontSize = 20; // initial font size

    // let textWidth = pdf.getTextWidth(page);
    // while (textWidth > pageWidth) {
    //   fontSize -= 1;
    //   pdf.setFontSize(fontSize);
    //   textWidth = pdf.getTextWidth(page);
    // }
    // const splitText = pdf.splitTextToSize(page, pageWidth);
    // pdf.html(page, {
    //   callback: () => {
    //     // Save the PDF
    //     pdf.save("example.pdf");
    //   },
    // });
    const doc = new jsPDF("potrait", "pt", "a3");
    doc.setFontSize(18);
    doc.text(
      "Halim Silalahi & partners",
      doc.internal.pageSize.getWidth() / 2,
      50,
      {
        align: "center",
      }
    );
    doc.autoTable({
      html: componentRef.current,
      orientation: "potrait",
      startY: 70,
      margin: { left: 0, right: 0 },
      tableWidth: "auto",
      theme: "plain",
      options: {
        pageSize: "A3",
      },
      styles: {
        lineWidth: 1, // set border width
      },
    });
    doc.save("table.pdf");
  };

  const dummyCreditor = [
    {
      tipe: "badan usaha",
      tipeKreditor: "separatis",
      nama: "PT Bank Central Asia",
      alamat: "Sudirman",
      nomorTelepon: "085156024563",
      email: "bca@bca.com",
      kuasaHukum: "Ombun",
      alamatKuasaHukum: "Condet",
      nomorTeleponKuasaHukum: "08132174192312",
    },
    {
      tipe: "perorangan",
      tipeKreditor: "preferen",
      nama: "Indra Prayoga Setyono",
      alamat: "Joglo",
      nomorTelepon: "085156024563",
      email: "indraprayogas21@gmail.com",
      nikKTP: "3173082405980009",
      kuasaHukum: "Ombun",
      alamatKuasaHukum: "Condet",
      nomorTeleponKuasaHukum: "08132174192312",
    },
    {
      tipe: "perorangan",
      tipeKreditor: "konkuren",
      nama: "Dimas",
      alamat: "Joglo",
      nomorTelepon: "085156024563",
      email: "dimas21@gmail.com",
      nikKTP: "3173082405980009",
      kuasaHukum: "Ombun",
      alamatKuasaHukum: "Condet",
      nomorTeleponKuasaHukum: "08132174192312",
    },
  ];

  const [printClicks, setPrintClicks] = useState(0);
  console.log("ini ref", componentRef.current);
  return (
    <main className="h-full overflow-y-auto">
      {isLoading && <LoadingSpinner />}

      <ReactToPrint
        key={printClicks}
        // trigger={() => <a href="#">Print</a>}
        content={() => componentRef.current}
        onBeforeGetContent={() => {
          // Force a re-render of the content before printing
          setPrintClicks(printClicks + 1);
        }}
      />
      {/* <button onClick={handleSave}>Print</button> */}
      <TableCreditorVerification
        creditors={props.creditors}
        totalData={totalData}
        totalPage={totalPage}
        page={page}
        showModalDetail={showModalDetail}
        showModalEdit={showModalEdit}
        changePage={changePage}
        setModalAdd={setModalAdd}
        dummyCreditor={dummyCreditor}
        getCreditors={props.getCreditors}
      />
      <TableCreditorVerificationPerusahaan
        creditors={props.creditors}
        totalData={totalData}
        totalPage={totalPage}
        page={page}
        showModalDetail={showModalDetail}
        showModalEdit={showModalEdit}
        changePage={changePage}
        setModalAdd={setModalAdd}
        dummyCreditor={dummyCreditor}
        getCreditors={props.getCreditors}
      />
      {/* <div className="" style={{ display: "none" }}>
        <TablePdf
          creditors={creditors}
          totalData={totalData}
          totalPage={totalPage}
          page={page}
          showModalDetail={showModalDetail}
          showModalEdit={showModalEdit}
          changePage={changePage}
          setModalAdd={setModalAdd}
          ref={componentRef}
        />
      </div> */}

      {/* <ModalEditCreditor
        modalEdit={modalEdit}
        offModalEdit={offModalEdit}
        dataEditTagihan={dataEditTagihan}
      />
      <ModalDetailCreditor
        modalDetail={modalDetail}
        offModalDetail={offModalDetail}
        data={detailCreditor}
      /> */}
    </main>
  );
};

export default MainCreditorVerification;

import axios from "axios";
import React, { useEffect, useState } from "react";
import AuthApi from "../../AuthApi";
import MainCreditor from "../../component/creditor/MainCreditor";
import Header from "../../component/template/Header";
import Sidebar from "../../component/template/Sidebar";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../component/template/LoadingSpinner";

const Creditor = () => {
  const Auth = React.useContext(AuthApi);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const params = useParams();
  const [dataCreditor, setDataCreditor] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const accessToken = await RefreshTokenService();
        const response = await axios({
          method: "GET",
          url: `${API_ENDPOINT.CREDITOR}/lawsuit/${params.lawsuitId}?page=1&limit=6&status=confirmed`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.status === 200) {
          setDataCreditor(response.data.data.creditors);
          console.log("res 200 creditor page", response);
        } else {
          console.log("else creditor page", response);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error creditor page", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [params.lawsuitId]);
  useEffect(() => {
    Auth.setPageActive("Kreditor");
  }, [Auth]);

  function toggleSideMenu() {
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar isSideMenuOpen={isSideMenuOpen} />
      <div className="flex flex-col flex-1 w-full overflow-auto">
        <Header toggleSideMenu={toggleSideMenu}></Header>
        {isLoading === true ? (
          <LoadingSpinner />
        ) : (
          <MainCreditor dataCreditor={dataCreditor} />
        )}
      </div>
    </div>
  );
};

export default Creditor;

import { Form, Image, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";

const ModalDetailCreditor = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {}, []);

  function closeModal() {
    form.resetFields();
    props.offModalDetail();
  }

  return (
    <div
      id="ModalDetailCreditor"
      tabIndex="-1"
      aria-hidden="true"
      className={`${
        !props.modalDetail ? "invisible" : ""
      } fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center overflow-auto`}
    >
      <div className="w-full max-h-[85vh] m-h-[85vh] px-6 py-4 overflow-auto bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl">
        <div className="relative bg-white rounded-lg dark:bg-gray-700">
          <div className="flex justify-between items-start rounded-t">
            <h3 className="p-4 text-xl 2xl:text-base font-semibold text-purple-900 dark:text-white">
              Detail Data
            </h3>

            <button
              onClick={closeModal}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Form Add Data Product */}

          <div className="max-w-lg m-auto space-y-5">
            <div className="bg-gray-200 rounded-md p-4 text-md">
              <h2>Detail Kreditur</h2>
              <div className="text-sm space-y-4">
                <hr className="border border-black" />
                <p className="">Nama: {props.data?.name}</p>
                <p className="">Email: {props.data?.email}</p>
                <p className="">No KTP: {props.data?.ktpNumber}</p>
                <p className="">Tipe Kreditur: {props.data?.creditorType}</p>
                <p className="">Address: {props.data?.address}</p>
                <p className="">Nomor Telepon: {props.data?.phoneNumber}</p>
                <div className="grid grid-cols-3 p-3 justify-between">
                  <Image className="px-3" src={props.data?.photocopyKtpLink} />
                  <Image className="px-3" src={props.data?.photocopyKtpLink} />
                  <Image className="px-3" src={props.data?.photocopyKtpLink} />
                </div>
              </div>
            </div>
            <div className="bg-gray-200 rounded-md p-4 text-md mt-2">
              <h2>Kuasa Hukum</h2>
              <div className="text-sm space-y-4">
                <hr className="border border-black" />
                {props.data?.attorney_address !== null && (
                  <p className="">Alamat: {props.data?.attorney_address}</p>
                )}
                <p className="">
                  Nomor Telepon: {props.data?.phoneNumberAttorney}
                </p>
                <p>Surat Kuasa:</p>
                <Image src={props.data?.powerOfAttorneyLink} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDetailCreditor;

import jsPDF from "jspdf";
import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import LoadingSpinner from "../template/LoadingSpinner";
import ModalEditCreditor from "./MainEditCreditor";
import ModalDetailCreditor from "./ModalDetailCreditor";
import TableCreditor from "./TableCreditor";
import TablePdf from "./TablePdf";
import "jspdf-autotable";
import TableCreditorPerusahaan from "./TableCreditorPerusahaan";
import ModalAddCreditor from "./ModalAddCreditor";

const MainCreditor = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [creditors, setCreditors] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [detailCreditor, setDetailCreditor] = useState([]);

  // Modal add tagihan
  const [modalAdd, setModalAdd] = useState(false);

  // Modal edit tagihan
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [dataEditTagihan, setDataEditTagihan] = useState({});

  const changePage = ({ selected }) => {
    setPage(selected + 1);
  };

  async function showModalEdit() {
    setModalEdit(true);
  }

  async function showModalDetail(id, index) {
    setLoading(true);
    setModalDetail(true);
    setLoading(false);
  }

  function offModalEdit() {
    setModalEdit(!modalEdit);
    setDataEditTagihan({});
  }
  function offModalDetail() {
    setModalDetail(!modalDetail);
  }

  const componentRef = useRef();
  const [dataModalEdit, setDataModalEdit] = useState({});
  function handleRowClick(data) {
    console.log("handle row click main creditor");
    setModalEdit(true);
    setDataModalEdit(data);
    console.log("modal edit", modalEdit);
  }

  const handleSave = () => {
    // const pdf = new jsPDF("potrait", "pt", "a3");
    // const page = componentRef.current;
    // const pageWidth = pdf.internal.pageSize.width - 20;
    // const fontSize = 20; // initial font size

    // let textWidth = pdf.getTextWidth(page);
    // while (textWidth > pageWidth) {
    //   fontSize -= 1;
    //   pdf.setFontSize(fontSize);
    //   textWidth = pdf.getTextWidth(page);
    // }
    // const splitText = pdf.splitTextToSize(page, pageWidth);
    // pdf.html(page, {
    //   callback: () => {
    //     // Save the PDF
    //     pdf.save("example.pdf");
    //   },
    // });
    const doc = new jsPDF("potrait", "pt", "a3");
    doc.setFontSize(18);
    doc.text(
      "Halim Silalahi & partners",
      doc.internal.pageSize.getWidth() / 2,
      50,
      {
        align: "center",
      }
    );
    doc.autoTable({
      html: componentRef.current,
      orientation: "potrait",
      startY: 70,
      margin: { left: 0, right: 0 },
      tableWidth: "auto",
      theme: "plain",
      options: {
        pageSize: "A3",
      },
      styles: {
        lineWidth: 1, // set border width
      },
    });
    doc.save("table.pdf");
  };

  const [printClicks, setPrintClicks] = useState(0);
  if (props.dataCreditor === null) {
    return <div></div>;
  }
  return (
    <main className="h-full overflow-y-auto">
      {isLoading && <LoadingSpinner />}

      <ReactToPrint
        key={printClicks}
        // trigger={() => <a href="#">Print</a>}
        content={() => componentRef.current}
        onBeforeGetContent={() => {
          // Force a re-render of the content before printing
          setPrintClicks(printClicks + 1);
        }}
      />
      {/* <button onClick={handleSave}>Print</button> */}
      <TableCreditor
        creditors={creditors}
        totalData={totalData}
        totalPage={totalPage}
        page={page}
        showModalDetail={showModalDetail}
        showModalEdit={showModalEdit}
        changePage={changePage}
        setModalAdd={setModalAdd}
        dataCreditor={props.dataCreditor}
        setModalEdit={setModalEdit}
        handleRowClick={handleRowClick}
        dataModalEdit={dataModalEdit}
        setDataModalEdit={setDataModalEdit}
      />
      <TableCreditorPerusahaan
        creditors={creditors}
        totalData={totalData}
        totalPage={totalPage}
        page={page}
        showModalDetail={showModalDetail}
        showModalEdit={showModalEdit}
        dataCreditor={props.dataCreditor}
        changePage={changePage}
        setModalAdd={setModalAdd}
        setModalEdit={setModalEdit}
        handleRowClick={handleRowClick}
        dataModalEdit={dataModalEdit}
        setDataModalEdit={setDataModalEdit}
      />
      <div className="" style={{ display: "none" }}>
        <TablePdf
          creditors={creditors}
          totalData={totalData}
          totalPage={totalPage}
          page={page}
          showModalDetail={showModalDetail}
          showModalEdit={showModalEdit}
          changePage={changePage}
          setModalAdd={setModalAdd}
          ref={componentRef}
        />
      </div>
      <ModalEditCreditor
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
        offModalEdit={offModalEdit}
        dataEditTagihan={dataEditTagihan}
        detailCreditor={detailCreditor}
        dataModalEdit={dataModalEdit}
        setDataModalEdit={setDataModalEdit}
      />
      <ModalDetailCreditor
        modalDetail={modalDetail}
        offModalDetail={offModalDetail}
        data={detailCreditor}
      />
      <ModalAddCreditor modalAdd={modalAdd} setModalAdd={setModalAdd} />
    </main>
  );
};

export default MainCreditor;

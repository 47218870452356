import moment from "moment";
import React from "react";
import { MdOutlineLibraryAdd } from "react-icons/md";
import ReactPaginate from "react-paginate";

const TablePerkara = (props) => {
  return (
    <div className="m-4">
      <div className="w-full overflow-hidden rounded-lg shadow-xs border">
        <div className="w-full overflow-x-scroll">
          <button
            onClick={() => props.setIsModalOpen(true)}
            className="space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary m-4 flex items-center text-white hover:bg-purple-600"
          >
            <MdOutlineLibraryAdd className="w-[20px] h-[20px]" />
            <span className="font-semibold">Menambahkan Perkara</span>
          </button>
          <table className="w-full table-auto whitespace-no-wrap">
            <thead>
              <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                <th className="px-4 py-3 truncate">Nama Klien</th>
                <th className="px-4 py-3 truncate">Tempat PN</th>
                <th className="px-4 py-3 truncate">No Putusan</th>
                <th className="px-4 py-3 truncate">Status Perkara</th>
                <th className="px-4 py-3 truncate">Tanggal Keputusan</th>
                <th className="px-4 py-3 truncate">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
              {props.dataPerkara &&
                props.dataPerkara?.map((data, index) => (
                  <tr
                    onClick={() => props.handleRowClick(data)}
                    className="text-gray-700 dark:text-gray-400 hover:bg-purple-200"
                    key={index}
                  >
                    <td className="px-2 py-3 text-sm capitalize">
                      {data.name}
                    </td>
                    <td className="px-2 py-3 text-sm capitalize">
                      {data.pengadilan_negri || "-"}
                    </td>
                    <td className="px-2 py-3 text-sm capitalize">
                      {data.no_putusan || "-"}
                    </td>
                    <td className="px-2 py-3 text-sm capitalize">
                      {data.type}
                    </td>
                    <td className="px-2 py-3 text-sm capitalize">
                      {moment(data.tanggal_putusan).format("DD-MMM-YYYY") ||
                        "-"}
                    </td>
                    <td className="px-2 py-3 text-sm capitalize">
                      {data.status === 0
                        ? "Non Aktif"
                        : data.status === 1
                        ? "Aktif"
                        : "-"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-wrap justify-between px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
        <p className="flex items-center col-span-3">
          Total Data : {props.totalData}
          <br /> Halaman : {props.page} Dari {props.totalPage}
        </p>
        <nav
          key={props.totalData}
          role="navigation"
          className="col-span-4 mt-2 sm:mt-4 sm:justify-end"
        >
          <ReactPaginate
            className="flex flex-wrap justify-center"
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={props.totalPage}
            onPageChange={props.changePage}
            containerClassName={"flex"}
            pageLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white dark:text-gray-400 dark:bg-gray-700"
            }
            previousLinkClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            nextLinkClassName={
              " text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            breakClassName={
              "text-sm 2xl:text-base inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
            }
            activeLinkClassName={
              "active-paginate dark:text-white dark:bg-gray-700"
            }
            disabledLinkClassName={
              "disable-paginate hover:bg-gray-300 hover:text-gray-500"
            }
          />
        </nav>
      </div>
    </div>
  );
};

export default TablePerkara;

import React, { useEffect, useState } from "react";
import AuthApi from "../../AuthApi";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tooltip,
  Upload,
  message,
} from "antd";
import compressImage from "../../utils/CompressImage";

const FormTagihan = (props) => {
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const Auth = React.useContext(AuthApi);
  const [form] = Form.useForm();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  // menyimpan list file gambar
  const [listRekap, setListRekap] = useState([]);
  const [listDasarTagihan, setListDasarTagihan] = useState([]);
  const [listBuktiPembayaran, setListBuktiPembayaran] = useState([]);
  // menyimpan gambar
  const [rekap, setRekap] = useState(null);
  const [dasarTagihan, setDasarTagihan] = useState(null);
  const [buktiPembayaran, setBuktiPembayaran] = useState(null);
  //properti gambar
  const propsRekap = {
    onRemove: (file) => {
      "remove terpanggil";
      const index = listRekap.indexOf(file);
      const newFileList = listRekap.slice();
      newFileList.splice(index, 1);
      setListRekap(newFileList);
    },
    beforeUpload: async (file) => {
      "before terpanggil";
      setListRekap([...listRekap, await compressImage(file)]);
      setRekap(await compressImage(file));

      return false;
    },
    listRekap,
  };

  const propsDasarTagihan = {
    onRemove: (file) => {
      const index = listDasarTagihan.indexOf(file);
      const newFileList = listDasarTagihan.slice();
      newFileList.splice(index, 1);
      setListDasarTagihan(newFileList);
    },
    beforeUpload: async (file) => {
      setListDasarTagihan([...listDasarTagihan, await compressImage(file)]);
      setDasarTagihan(await compressImage(file));

      return false;
    },
    listDasarTagihan,
  };

  const propsBuktiPembayaran = {
    onRemove: (file) => {
      const index = listBuktiPembayaran.indexOf(file);
      const newFileList = listBuktiPembayaran.slice();
      newFileList.splice(index, 1);
      setListBuktiPembayaran(newFileList);
    },
    beforeUpload: async (file) => {
      setListBuktiPembayaran([
        ...listBuktiPembayaran,
        await compressImage(file),
      ]);
      setBuktiPembayaran(await compressImage(file));

      return false;
    },
    listBuktiPembayaran,
  };

  //on change gambar
  const onChangedRekap = async (info) => {
    setRekap(await compressImage(info.file));
  };
  const onChangedDasarTagihan = async (info) => {
    setDasarTagihan(await compressImage(info.file));
  };
  const onChangedBuktiPembayaran = async (info) => {
    setBuktiPembayaran(await compressImage(info.file));
  };

  // const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  // function toggleSideMenu() {
  //   const hamburger = document.getElementById("humbergerButton");
  //   hamburger.classList.toggle("hamburger-active");
  //   setIsSideMenuOpen(!isSideMenuOpen);
  // }

  const onFinish = async (values) => {
    message.success("Berhasil menambahkan tagihan");
    if (rekap != null) {
      values.billRecapitulation = rekap;
    }
    if (buktiPembayaran != null) {
      values.proofOfPayment = buktiPembayaran;
    }
    if (dasarTagihan != null) {
      values.fundamentalBill = dasarTagihan;
    }
    props.setTagihan((prevArray) => [...prevArray, values]);
    form.resetFields();
    setRekap(null);
    setBuktiPembayaran(null);
    setDasarTagihan(null);
    setListRekap([]);
    setListDasarTagihan([]);
    setListBuktiPembayaran([]);
    // setLoading(true);
    // const formData = new FormData();
    // formData.append("invoiceNumber", values.invoiceNumber);
    // formData.append("billNature", values.billNature);
    // formData.append("principalAmount", values.principalAmount.toString());
    // formData.append("interest", values.interest.toString());
    // formData.append("amercement", values.amercement.toString());
    // if (values.fundamentalBill != null) {
    //   formData.append("fundamentalBill", dasarTagihan);
    // }
    // if (values.billRecapitulation != null) {
    //   formData.append("billRecapitulation", rekap);
    // }
    // if (values.proofOfPayment != null) {
    //   formData.append("proofOfPayment", buktiPembayaran);
    // }
    // var data = JSON.stringify({
    //   refreshToken: `${Cookies.get("refreshToken")}`,
    // });
    // // You can use any AJAX library you like
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    // try {
    //   let accessToken;
    //   var configuration = {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   };
    //   const dataToken = await axios.put(
    //     `${CONFIG.BASE_URL}/authentication`,
    //     data,
    //     configuration
    //   );
    //   accessToken = dataToken.data.data.accessToken;
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   };
    //   let response = await axios.post(
    //     `${CONFIG.BASE_URL}/invoices`,
    //     formData,
    //     config
    //   );
    //   response = response.data;
    //   setLoading(false);
    //   if (response.status === "success") {
    //     ToastNoify("success", response.message);
    //     form.resetFields();
    //   } else {
    //     ToastNoify("error", response.message);
    //   }
    // } catch (error) {
    //   console.error(error);
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    Auth.setPageActive("menambahkan-tagihan");
  });

  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const parser = (value) => value.replace(/Rp.\s?|(,*)/g, "");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <div className="flex flex-col overflow-y-auto md:flex-row">
      <div className="w-2/3 mx-auto">
        <Form
          form={form}
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          scrollToFirstError
          // style={{
          //   maxWidth: "w-3/5",
          // }}
        >
          <Form.Item
            label={
              <Tooltip title="Checklist jika menggunakan kuasa hukum">
                Sifat Tagihan
              </Tooltip>
            }
            name="billNature"
            initialValue={"konkuren"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Select.Option value="konkuren">Kreditor Konkuren</Select.Option>
              <Select.Option value="preferen">Kreditor Preferen</Select.Option>
              <Select.Option value="separatis">
                Kreditor Separatis
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={"invoiceNumber"}
            label="No Tagihan"
            rules={[
              {
                required: true,
                message: "No tagihan wajib di isi",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="principalAmount" label="Tagihan Pokok">
            <InputNumber
              formatter={formatter}
              parser={parser}
              className="w-full"
            />
          </Form.Item>
          <Form.Item name="interest" label="Bunga">
            <InputNumber
              formatter={formatter}
              parser={parser}
              className="w-full"
            />
          </Form.Item>
          <Form.Item name="amercement" label="Denda">
            <InputNumber
              formatter={formatter}
              parser={parser}
              className="w-full"
            />
          </Form.Item>
          <div className="flex flex-wrap justify-between">
            <Form.Item label="Rekapitulasi Tagihan" name={"billRecapitulation"}>
              <Upload
                accept="image/*"
                {...propsRekap}
                onChange={onChangedRekap}
                listType="picture-card"
                onPreview={handlePreview}
              >
                {listRekap.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Select File
                    </div>
                  </div>
                )}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
            <Form.Item label="Dasar Tagihan" name={"fundamentalBill"}>
              <Upload
                accept="image/*"
                {...propsDasarTagihan}
                onChange={onChangedDasarTagihan}
                listType="picture-card"
                onPreview={handlePreview}
              >
                {listDasarTagihan.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Select File
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item label="Bukti Pembayaran" name={"proofOfPayment"}>
              <Upload
                accept="image/*"
                {...propsBuktiPembayaran}
                onChange={onChangedBuktiPembayaran}
                listType="picture-card"
                onPreview={handlePreview}
              >
                {listBuktiPembayaran.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Select File
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </div>

          <Form.Item style={{ display: "flex", justifyContent: "center" }}>
            <button className="ant-btn ant-btn-primary mt-5 bg-purple-600 hover:bg-purple-700 py-2 px-5 rounded-md text-white ">
              Tambah Tagihan
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default FormTagihan;

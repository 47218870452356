import { Form, Input, InputNumber, Modal, Select, Tooltip, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import compressImage from "../../utils/CompressImage";

const ModalAddTagihan = (props) => {
  const [form] = Form.useForm();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  // menyimpan list file gambar
  const [listRekap, setListRekap] = useState([]);
  const [listDasarTagihan, setListDasarTagihan] = useState([]);
  const [listBuktiPembayaran, setListBuktiPembayaran] = useState([]);
  // menyimpan gambar
  const [rekap, setRekap] = useState(null);
  const [dasarTagihan, setDasarTagihan] = useState(null);
  const [buktiPembayaran, setBuktiPembayaran] = useState(null);

  function closeModal() {
    form.resetFields();
    setListRekap([]);
    setListDasarTagihan([]);
    setListBuktiPembayaran([]);
    props.setModalAdd(false);
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //properti gambar
  const propsRekap = {
    onRemove: (file) => {
      const index = listRekap.indexOf(file);
      const newFileList = listRekap.slice();
      newFileList.splice(index, 1);
      setListRekap(newFileList);
    },
    beforeUpload: async (file) => {
      setListRekap([...listRekap, await compressImage(file)]);
      setRekap(await compressImage(file));

      return false;
    },
    listRekap,
  };

  const propsDasarTagihan = {
    onRemove: (file) => {
      const index = listDasarTagihan.indexOf(file);
      const newFileList = listDasarTagihan.slice();
      newFileList.splice(index, 1);
      setListDasarTagihan(newFileList);
    },
    beforeUpload: async (file) => {
      setListDasarTagihan([...listDasarTagihan, await compressImage(file)]);
      setDasarTagihan(await compressImage(file));

      return false;
    },
    listDasarTagihan,
  };

  const propsBuktiPembayaran = {
    onRemove: (file) => {
      const index = listBuktiPembayaran.indexOf(file);
      const newFileList = listBuktiPembayaran.slice();
      newFileList.splice(index, 1);
      setListBuktiPembayaran(newFileList);
    },
    beforeUpload: async (file) => {
      setListBuktiPembayaran([
        ...listBuktiPembayaran,
        await compressImage(file),
      ]);
      setRekap(await compressImage(file));

      return false;
    },
    listBuktiPembayaran,
  };

  //on change gambar
  const onChangedRekap = async (info) => {
    setRekap(await compressImage(info.file));
  };
  const onChangedDasarTagihan = async (info) => {
    setDasarTagihan(await compressImage(info.file));
  };
  const onChangedBuktiPembayaran = async (info) => {
    setBuktiPembayaran(await compressImage(info.file));
  };

  const onFinish = async (values) => {
    // console.log(values)
    props.setLoading(true);
    const formData = new FormData();
    formData.append("invoiceNumber", values.invoiceNumber);
    formData.append("billNature", values.billNature);
    formData.append("principalAmount", values.principalAmount.toString());
    formData.append("interest", values.interest.toString());
    formData.append("amercement", values.amercement.toString());
    if (values.fundamentalBill != null) {
      formData.append("fundamentalBill", dasarTagihan);
    }
    if (values.billRecapitulation != null) {
      formData.append("billRecapitulation", rekap);
    }
    if (values.proofOfPayment != null) {
      formData.append("proofOfPayment", buktiPembayaran);
    }

    form.resetFields();
    setListRekap([]);
    setListDasarTagihan([]);
    setListBuktiPembayaran([]);

    await props.addInvoice(formData);
  };

  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const parser = (value) => value.replace(/\Rp.\s?|(,*)/g, "");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <div
      id="modalAddTagihan"
      tabIndex="-1"
      aria-hidden="true"
      className={`${
        !props.modalAdd ? "invisible" : ""
      } fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center overflow-auto`}
    >
      <div className="w-full max-h-[85vh] min-h-[600px] px-6 py-4 overflow-auto bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl">
        <div className="relative bg-white rounded-lg dark:bg-gray-700">
          <div className="flex justify-between items-start rounded-t">
            <h3 className="p-4 text-sm 2xl:text-base font-semibold text-gray-900 dark:text-white">
              Menambahkan Tagihan
            </h3>
            <button
              onClick={closeModal}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Form Add Data Tagihan */}
          <Form
            form={form}
            layout="vertical"
            name="nest-messages"
            onFinish={onFinish}
          >
            <div className="max-w-lg m-auto space-y-5">
              <Form.Item
                label={
                  <Tooltip
                    className="dark:text-gray-200"
                    title="Checklist jika menggunakan kuasa hukum"
                  >
                    Sifat Tagihan
                  </Tooltip>
                }
                name="billNature"
                initialValue={"konkuren"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="konkuren">
                    Kreditor Konkuren
                  </Select.Option>
                  <Select.Option value="preferen">
                    Kreditor Preferen
                  </Select.Option>
                  <Select.Option value="separatis">
                    Kreditor Separatis
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="No Tagihan"
                rules={[
                  {
                    required: true,
                    message: "No tagihan wajib di isi",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="principalAmount" label="Tagihan Pokok">
                <InputNumber
                  formatter={formatter}
                  parser={parser}
                  className="w-full"
                />
              </Form.Item>
              <Form.Item label="Bunga" name="interest">
                <InputNumber
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              </Form.Item>
              <Form.Item name="amercement" label="Denda">
                <InputNumber
                  formatter={formatter}
                  parser={parser}
                  className="w-full"
                />
              </Form.Item>
              <div className="flex justify-between flex-wrap">
                <Form.Item
                  label="Rekapitulasi Tagihan"
                  name={"billRecapitulation"}
                >
                  <Upload
                    accept="image/*"
                    {...propsRekap}
                    onChange={onChangedRekap}
                    listType="picture-card"
                    onPreview={handlePreview}
                  >
                    {listRekap.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Select File
                        </div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </Modal>
                <Form.Item label="Dasar Tagihan" name={"fundamentalBill"}>
                  <Upload
                    accept="image/*"
                    {...propsDasarTagihan}
                    onChange={onChangedDasarTagihan}
                    listType="picture-card"
                    onPreview={handlePreview}
                  >
                    {listDasarTagihan.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Select File
                        </div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
                <Form.Item label="Bukti Pembayaran" name={"proofOfPayment"}>
                  <Upload
                    accept="image/*"
                    {...propsBuktiPembayaran}
                    onChange={onChangedBuktiPembayaran}
                    listType="picture-card"
                    onPreview={handlePreview}
                  >
                    {listBuktiPembayaran.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Select File
                        </div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
              </div>
            </div>
            <div className="flex justify-end items-center p-6 pb-0 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
              <Form.Item>
                <button
                  data-modal-toggle="defaultModal"
                  type="submit"
                  className="text-white bg-primary dark:bg-gray-700 dark:border-white dark:border hover:drop-shadow-xl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  dark:hover:bg-gray-600 dark:hover:text-gray-200"
                >
                  Save Data Tagihan
                </button>
              </Form.Item>
              <Form.Item>
                <button
                  onClick={closeModal}
                  data-modal-toggle="defaultModal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-white dark:text-gray-700 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Cancel
                </button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ModalAddTagihan;

import { message, Modal, Upload } from "antd";
import React from "react";
import { useRef } from "react";
import { BsPrinterFill } from "react-icons/bs";
import ReactToPrint from "react-to-print";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import Cookies from "js-cookie";
import LoadingSpinner from "../../component/template/LoadingSpinner";
import axios from "axios";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";
import compressImage from "../../utils/CompressImage";

const FormPengajuan = () => {
  const [dataCookies, setDataCookies] = useState({
    creditorId: null,
    dataPersonal: null,
    dataTagihan: null,
  });
  useEffect(() => {
    const test = Cookies.get("dataRegister");
    const jsonCookie = JSON.parse(test);
    console.log(jsonCookie);
    setDataCookies(jsonCookie);
  }, []);
  const uploadSignature = async () => {
    console.log("test");
    let data = new FormData();
    data.append("signature", signature);
    let config = {
      method: "post",
      url: `${API_ENDPOINT.CREDITOR}/signature/${dataCookies.creditorId}`,
      data: data,
    };
    const res = await axios.request(config);
    console.log("res form pengajuan", res);
    if (res.status === 200) {
      Cookies.set("uploadSignature", "true", { expires: 1 });
      message.success(res.data.status);
    } else {
      message.error(res.data.status);
    }
  };

  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    console.log("handle preview form pengajuan", file);
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const [signature, setSignature] = useState(null);
  const [listSignature, setListSignature] = useState([]);
  const properties = {
    onRemove: (file) => {
      "remove terpanggil";
      const index = listSignature.indexOf(file);
      const newFileList = listSignature.slice();
      newFileList.splice(index, 1);
      setListSignature(newFileList);
    },
    beforeUpload: async (file) => {
      "before terpanggil";
      setListSignature([...listSignature, await compressImage(file)]);
      setSignature(await compressImage(file));

      return false;
    },
    listSignature,
  };
  const onChanged = async (info) => {
    setSignature(await compressImage(info.file));
  };
  const printContentRef = useRef(null);
  if (dataCookies.dataPersonal === null || dataCookies.dataTagihan === null) {
    return <LoadingSpinner />;
  }
  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-3xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800 p-4">
        <h1 className="text-center font-bold text-gray-600 border-b-2 p-4">
          Upload Form Pengajuan
        </h1>
        <div className="content p-2 m-4">
          <ReactToPrint
            className="space-x-2 text-sm border border-primary rounded-lg w-max bg-primary flex items-center text-white hover:bg-purple-600"
            trigger={() => (
              <button className="mb-3 print:hidden space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary flex items-center text-white hover:bg-purple-600">
                <BsPrinterFill className="w-[20px] h-[20px]" />
                <span className="print:hidden font-semibold mr-4">
                  Save & Print
                </span>
              </button>
            )}
            content={() => printContentRef.current}
          ></ReactToPrint>
          <p className="font-semibold text-gray-500 mb-3">
            Print form pengajuan kemudian upload form yang sudah di tanda
            tangani
          </p>

          <Upload
            accept="image/*"
            {...properties}
            onChange={onChanged}
            listType="picture-card"
            onPreview={handlePreview}
          >
            {listSignature.length >= 1 ? null : (
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Select File
                </div>
              </div>
            )}
          </Upload>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
          <button
            onClick={() => uploadSignature()}
            className="print:hidden mt-10 mx-auto space-x-2 text-sm border border-primary rounded-lg w-max p-2 bg-primary flex items-center text-white hover:bg-purple-600"
          >
            <span className="print:hidden font-semibold mx-4">Submit</span>
          </button>
        </div>
        <div className="">
          {dataCookies &&
          dataCookies.dataPersonal &&
          dataCookies.dataTagihan ? (
            <div className="mt-4">
              <div
                className="printable hidden print:block print:mx-8"
                // className="printable mx-8"
                ref={printContentRef}
              >
                <h2 className="w-full text-center font-bold mb-16 border">
                  FORMULIR PENGAJUAN KLAIM/TAGIHAN KEPADA PT GRIYA KARUNIA
                  SEJAHTERA (DALAM PKPU)
                </h2>
                <div className="flex space-x-5 w-full">
                  <table className="border">
                    <thead>
                      <tr>
                        <th colSpan={2}>Data Kreditor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border text-sm p-3">Tipe Kreditor</td>
                        <td className="border text-sm p-3">
                          {capitalizeFirstLetter(
                            dataCookies.dataPersonal.values.tipeKreditor
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="border text-sm p-3">Nama</td>
                        <td className="border text-sm p-3">
                          {capitalizeFirstLetter(
                            dataCookies.dataPersonal.values.nama
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="border text-sm p-3">Alamat</td>
                        <td className="border text-sm p-3">
                          {capitalizeFirstLetter(
                            dataCookies.dataPersonal.values.alamat
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="border text-sm p-3">No Telepon</td>
                        <td className="border text-sm p-3">
                          {`0${dataCookies.dataPersonal.values.phone}`}
                        </td>
                      </tr>
                      <tr>
                        <td className="border text-sm p-3">Email</td>
                        <td className="border text-sm p-3">
                          {dataCookies.dataPersonal.values.email}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {dataCookies.dataPersonal.values.hasOwnProperty(
                    "attorneyName"
                  ) &&
                    dataCookies.dataPersonal.values.attorneyName !== null && (
                      <table className="border">
                        <thead>
                          <tr>
                            <th colSpan={2}>Data Kuasa Hukum</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border text-sm p-3">Nama</td>
                            <td className="border text-sm p-3">
                              {capitalizeFirstLetter(
                                dataCookies.dataPersonal.values.attorneyName
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="border text-sm p-3">Alamat</td>
                            <td className="border text-sm p-3">
                              {capitalizeFirstLetter(
                                dataCookies.dataPersonal.values.alamatAttorney
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="border text-sm p-3">No Telepon</td>
                            <td className="border text-sm p-3">
                              {`0${dataCookies.dataPersonal.values.phone_attorney}`}
                            </td>
                          </tr>
                          <tr>
                            <td className="border text-sm p-3">Email</td>
                            <td className="border text-sm p-3">
                              {dataCookies.dataPersonal.values.email}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                </div>

                <div className="piutang my-4">
                  <table className="border">
                    <thead>
                      <tr>
                        <th colSpan={7}>Rincian Piutang</th>
                      </tr>
                      <tr>
                        <th className="border truncate text-sm p-2">No</th>
                        <th className="border truncate text-sm p-2">
                          Sifat Tagihan
                        </th>
                        <th className="border truncate text-sm p-2">
                          No Tagihan
                        </th>
                        <th className="border truncate text-sm p-2">
                          Tagihan Pokok
                        </th>
                        <th className="border truncate text-sm p-2">Bunga</th>
                        <th className="border truncate text-sm p-2">Denda</th>
                        <th className="border truncate text-sm p-2">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataCookies.dataTagihan.map((data, index) => (
                        <tr key={index}>
                          <td className="border text-sm p-3">{index + 1}</td>
                          <td className="border text-sm p-3">
                            {capitalizeFirstLetter(data.billNature)}
                          </td>
                          <td className="border text-sm p-3">
                            {data.invoiceNumber}
                          </td>
                          <td className="border text-sm p-3 truncate">
                            {formatter(`${data.principalAmount || "0"}`)}
                          </td>
                          <td className="border text-sm p-3 truncate">
                            {formatter(`${data.interest || "0"}`)}
                          </td>
                          <td className="border text-sm p-3 truncate">
                            {formatter(`${data.amercement || "0"}`)}
                          </td>
                          <td className="border text-sm p-3 truncate">
                            {formatter(
                              `${
                                (parseInt(data.principalAmount) || 0) +
                                (parseInt(data.interest) || 0) +
                                (parseInt(data.amercement) || 0)
                              }`
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p className="text-sm">
                  Demikian rincian piutang kami kepada PT Griya Karunia
                  Sejahtera (Dalam PKPU) dan dengan ini menyatakan dan menjamin
                  bahwa perhitungan piutang kami sebagaimana tertera diatas
                  adalah benar
                </p>
                <div className="ttd mt-10">
                  <table className="w-[600px]">
                    <tbody>
                      <tr className="">
                        <td>Jakarta, .....................</td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">PEMBERI KUASA</td>
                        <td className="w-[150px]"> </td>
                        <td className="text-center">PENERIMA KUASA</td>
                      </tr>
                      <tr className=" min-h-[150px]">
                        <td className="">
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <hr />
                        </td>
                        <td> </td>
                        <td>
                          <hr />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormPengajuan;

import { DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import React from "react";
import { useEffect } from "react";
import moment from "moment";

const ModalEditPerkara = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.dummyDataModal) {
      form.setFieldsValue({
        nameCircumstance: props.dummyDataModal.name,
        pn: props.dummyDataModal.pengadilan_negri,
        noPutusan: props.dummyDataModal.no_putusan,
        statusPerkara: props.dummyDataModal.type,
        kurs_america: props.dummyDataModal.kurs_america,
        kurs_australia: props.dummyDataModal.kurs_australia,
        kurs_china: props.dummyDataModal.kurs_china,
        kurs_euro: props.dummyDataModal.kurs_euro,
        kurs_hongkong: props.dummyDataModal.kurs_hongkong,
        kurs_jepang: props.dummyDataModal.kurs_jepang,
        kurs_kanada: props.dummyDataModal.kurs_kanada,
        kurs_malaysia: props.dummyDataModal.kurs_malaysia,
        kurs_singapore: props.dummyDataModal.kurs_singapore,
        dateCircumstance: moment(props.dummyDataModal.tanggal_putusan),
        statusCircumstance:
          props.dummyDataModal.status === 1 ? "aktif" : "nonaktif",
      });
    }
  }, [props.dummyDataModal, form]);
  const formatter = (value) =>
    `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const parser = (value) => value.replace(/Rp.\s?|(,*)/g, "");
  return (
    <Modal
      title="Edit Perkara"
      open={props.modalEdit}
      onOk={() => {
        props.setDummyDataModal(null);
        props.setModalEdit(false);
      }}
      onCancel={() => {
        props.setDummyDataModal(null);
        props.setModalEdit(false);
      }}
      okButtonProps={{ style: { background: "blue", color: "white" } }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          name="nameCircumstance"
          label="Nama Klien"
          key={props.dummyDataModal && props.dummyDataModal.nama}
        >
          <Input
            defaultValue={props.dummyDataModal && props.dummyDataModal.nama}
          />
        </Form.Item>
        <Form.Item
          name="pn"
          label="Tempat Pengadilan Negeri"
          key={props.dummyDataModal && props.dummyDataModal.pn}
        >
          <Input
            defaultValue={props.dummyDataModal && props.dummyDataModal.pn}
          />
        </Form.Item>
        <Form.Item
          name="noPutusan"
          label="Nomor Putusan"
          key={props.dummyDataModal && props.dummyDataModal.noPutusan}
        >
          <Input
            defaultValue={
              props.dummyDataModal && props.dummyDataModal.noPutusan
            }
          />
        </Form.Item>
        <Form.Item
          name="statusPerkara"
          label="Status Perkara"
          key={props.dummyDataModal && props.dummyDataModal.statusPerkara}
        >
          <Select
            placeholder="Jenis Perkara"
            allowClear
            defaultValue={
              props.dummyDataModal && props.dummyDataModal.statusPerkara
            }
          >
            <Select.Option value="PKPU">PKPU</Select.Option>
            <Select.Option value="Kepailitan">Kepailitan</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="dateCircumstance" label="Tanggal Keputusan">
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="statusCircumstance" label="Status Perkara">
          <Select placeholder="Pilih status perkara" allowClear>
            <Select.Option value="aktif">Aktif</Select.Option>
            <Select.Option value="nonaktif">Non Aktif</Select.Option>
          </Select>
        </Form.Item>
        <h2 className="font-bold">KURS</h2>
        <div className="flex gap-3">
          <div className="w-1/2">
            <Form.Item name="kurs_america" label="Kurs Dollar Amerika">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item name="kurs_singapore" label="Kurs Dollar Singapura">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item name="kurs_euro" label="Kurs Euro">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item name="kurs_australia" label="Kurs Dollar Australia">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item name="kurs_china" label="Kurs Yuan Cina">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="kurs_jepang" label="Kurs Yuan Jepang">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item name="kurs_hongkong" label="Kurs Dollar Hongkong">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item name="kurs_kanada" label="Kurs Dollar Kanada">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item name="kurs_malaysia" label="Kurs Ringgit Malaysia">
              <InputNumber
                className="w-full"
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditPerkara;

import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CreditorService from "../../app/Services/CreditorService";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import AuthApi from "../../AuthApi";
import ToastNoify from "../../utils/ToastNotify";
import Header from "../template/Header";
import LoadingSpinner from "../template/LoadingSpinner";
import Sidebar from "../template/Sidebar";
import MainCreditorVerification from "./MainCreditorVerification";

const CreditorVerification = () => {
  const Auth = React.useContext(AuthApi);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const creditorService = new CreditorService();
  const params = useParams();

  const [creditors, setCreditors] = useState([]);

  useEffect(() => {
    Auth.setPageActive("Verifikasi Kreditor");
  }, [Auth]);
  useEffect(() => {
    getCreditors();
  }, [params]);

  const getCreditors = async () => {
    setLoading(true);
    const accessToken = await RefreshTokenService();
    if (accessToken === "fail") {
      Auth.setAuth(false);
      Cookies.remove("refreshToken");
      Cookies.remove("user");
    }
    const lawsuitId = params.lawsuitId;
    const result = await creditorService.getCreditorWaiting({
      accessToken,
      lawsuitId,
    });

    if (result.status === "success") {
      setCreditors(result.data.creditors);
      console.log("creditorverification page", result);
    } else {
      ToastNoify("error", result.message);
    }

    setLoading(false);
  };
  function toggleSideMenu() {
    setIsSideMenuOpen(!isSideMenuOpen);
  }

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar isSideMenuOpen={isSideMenuOpen} />
      <div className="flex flex-col flex-1 w-full overflow-auto">
        <Header toggleSideMenu={toggleSideMenu}></Header>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <MainCreditorVerification
            creditors={creditors}
            getCreditors={getCreditors}
          />
        )}
      </div>
    </div>
  );
};

export default CreditorVerification;

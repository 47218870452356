import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { TbBrandReactNative } from "react-icons/tb";
import { BsBriefcase } from "react-icons/bs";
import { MdOutlineDashboard } from "react-icons/md";
import AuthApi from "../../AuthApi";
import { GrUserAdmin } from "react-icons/gr";
import RefreshTokenService from "../../app/Services/RefreshTokenService";
import axios from "axios";
import API_ENDPOINT from "../../app/Config/API_ENDPOINT";
import dayjs from "dayjs";
import { message } from "antd";

const ContentSidebar = (props) => {
  const Auth = React.useContext(AuthApi);
  const [isPagesMenuOpen, setIsPagesMenuOpen] = useState(false);
  const [isCompanyDropdown, setIsCompanyDropdown] = useState(null);
  const [isPkpuMenuOpen, setIsPkpuMenuOpen] = useState(false);
  const [isKepailitanMenuOpen, setIsKepailitanMenuOpen] = useState(false);
  const navigate = useNavigate();

  function togglePagesMenu() {
    setIsPagesMenuOpen(!isPagesMenuOpen);
  }
  const exportData = async (lawsuitId) => {
    const token = await RefreshTokenService();
    const res = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${API_ENDPOINT.LAWSUIT}/export/${lawsuitId}?time=${dayjs}`,
      method: "GET",
    });
    if (res.status === 200) {
      const path = res.data.data.path;
      window.open(path, "_blank");
    } else {
      message.error(res.data.message);
    }
  };

  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <NavLink
        to={"/dashboard"}
        className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200 flex items-center"
      >
        <TbBrandReactNative className="w-[40px] h-[40px] text-purple-800" />
        <h2>PKPU</h2>
      </NavLink>
      <ul className="mt-6">
        <li className="relative px-6 py-3">
          {Auth.pageActive === "dashboard" && (
            <span
              className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
          )}
          <NavLink
            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 sidebar-active"
            to={"/dashboard"}
          >
            <MdOutlineDashboard />
            <span className="ml-4">Dashboard</span>
          </NavLink>
        </li>
      </ul>
      <ul className="">
        <li className="relative px-6 py-3">
          {Auth.pageActive === "Buat Perkara" && (
            <span
              className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
          )}
          <NavLink
            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 sidebar-active"
            to={"/perkara"}
          >
            <BsBriefcase />
            <span className="ml-4">Buat Perkara</span>
          </NavLink>
        </li>
      </ul>
      <ul className="">
        <li className="relative px-6 py-3">
          {Auth.pageActive === "Admin" && (
            <span
              className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
          )}
          <NavLink
            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 sidebar-active"
            to={"/admin"}
          >
            <GrUserAdmin />
            <span className="ml-4">Admin</span>
          </NavLink>
        </li>
      </ul>
      <ul>
        <li className="relative px-6 py-3">
          {Auth.pageActive === "Perkara" && (
            <span
              className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
          )}
          <button
            className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
            onClick={togglePagesMenu}
            aria-haspopup="true"
          >
            <span className="inline-flex items-center">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
              </svg>
              <span className="ml-4">Perkara</span>
            </span>
            <svg
              className={`w-4 h-4 ${
                isPagesMenuOpen ? "svg-dropdown" : "svg-updown"
              }`}
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          {isPagesMenuOpen && (
            <div className="dropdown-animation">
              <ul
                className=" overflow-hidden text-sm font-medium text-gray-500 "
                aria-label="submenu"
              >
                <li className="pl-2 pt-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                  <button
                    className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
                    onClick={() => setIsPkpuMenuOpen(!isPkpuMenuOpen)}
                    aria-haspopup="true"
                  >
                    <span className="">PKPU</span>
                    <svg
                      className={`w-4 h-4 ${
                        isPkpuMenuOpen ? "svg-dropdown" : "svg-updown"
                      }`}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>

                  {isPkpuMenuOpen && (
                    <div className="rounded-md shadow-inner bg-gray-50 p-2 mt-2 space-y-4 overflow-hidden text-sm font-medium text-gray-500">
                      {props.dataPKPU.map((data, index) => (
                        <React.Fragment key={index}>
                          {data.type === "Dalam PKPU" ||
                          data.type === "PKPU" ? (
                            <ul id={data.lawsuit_id} key={index}>
                              <button
                                className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
                                onClick={() => {
                                  if (isCompanyDropdown !== data.lawsuit_id) {
                                    setIsCompanyDropdown(data.lawsuit_id);
                                  } else {
                                    setIsCompanyDropdown(null);
                                  }
                                }}
                                aria-haspopup="true"
                              >
                                <span className="text-left">{data.name}</span>
                                <svg
                                  className={`w-4 h-4 ${
                                    isCompanyDropdown === data.lawsuit_id
                                      ? "svg-dropdown"
                                      : "svg-updown"
                                  }`}
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              {isCompanyDropdown === data.lawsuit_id && (
                                <hr className="mt-2"></hr>
                              )}
                              {isCompanyDropdown === data.lawsuit_id && (
                                <ul className="pl-3">
                                  <li>
                                    <button
                                      className="my-2 text-purple-900 inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800  focus:outline-none"
                                      onClick={() =>
                                        // setIsPkpuMenuOpen(!isPkpuMenuOpen)
                                        navigate(`/creditor/${data.lawsuit_id}`)
                                      }
                                      aria-haspopup="true"
                                    >
                                      <span className="">Kreditor</span>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="my-2 text-purple-900 inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800  focus:outline-none"
                                      onClick={() =>
                                        // setIsPkpuMenuOpen(!isPkpuMenuOpen)
                                        navigate(
                                          `/verifikasi-kreditor/${data.lawsuit_id}`
                                        )
                                      }
                                      aria-haspopup="true"
                                    >
                                      <span className="">
                                        Verifikasi Kreditor
                                      </span>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="my-2 text-purple-900 inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800  focus:outline-none"
                                      onClick={() =>
                                        // setIsPkpuMenuOpen(!isPkpuMenuOpen)
                                        navigate(`/tagihan/${data.lawsuit_id}`)
                                      }
                                      aria-haspopup="true"
                                    >
                                      <span className="">Daftar Piutang</span>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="my-2 text-purple-900 inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800  focus:outline-none"
                                      onClick={() =>
                                        // setIsPkpuMenuOpen(!isPkpuMenuOpen)
                                        // navigate(`/tagihan/${data.lawsuit_id}`)
                                        exportData(data.lawsuit_id)
                                      }
                                      aria-haspopup="true"
                                    >
                                      <span className="">Export data</span>
                                    </button>
                                  </li>
                                </ul>
                              )}
                            </ul>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </li>
                {/* //kepailitan */}
                <li className="pl-2 pt-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                  <button
                    className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
                    onClick={() =>
                      setIsKepailitanMenuOpen(!isKepailitanMenuOpen)
                    }
                    aria-haspopup="true"
                  >
                    <span className="">Kepailitan</span>
                    <svg
                      className={`w-4 h-4 ${
                        isKepailitanMenuOpen ? "svg-dropdown" : "svg-updown"
                      }`}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  {isKepailitanMenuOpen && (
                    <div className="rounded-md shadow-inner bg-gray-50 p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500">
                      {props.dataPKPU.map((data, index) => (
                        <React.Fragment key={index}>
                          {data.type === "Kepailitan" && (
                            <ul id={data.lawsuit_id} key={index}>
                              <button
                                className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
                                onClick={() => {
                                  if (isCompanyDropdown !== data.lawsuit_id) {
                                    setIsCompanyDropdown(data.lawsuit_id);
                                  } else {
                                    setIsCompanyDropdown(null);
                                  }
                                }}
                                aria-haspopup="true"
                              >
                                <span className="">{data.name}</span>
                                <svg
                                  className={`w-4 h-4 ${
                                    isCompanyDropdown === data.lawsuit_id
                                      ? "svg-dropdown"
                                      : "svg-updown"
                                  }`}
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                              {isCompanyDropdown === data.lawsuit_id && (
                                <hr className="mt-2"></hr>
                              )}
                              {isCompanyDropdown === data.lawsuit_id && (
                                <ul className="pl-3">
                                  <li>
                                    <button
                                      className="my-2 text-purple-900 inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800  focus:outline-none"
                                      onClick={() =>
                                        // setIsPkpuMenuOpen(!isPkpuMenuOpen)
                                        navigate(`/creditor/${data.lawsuit_id}`)
                                      }
                                      aria-haspopup="true"
                                    >
                                      <span className="">Kreditor</span>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="my-2 text-purple-900 inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800  focus:outline-none"
                                      onClick={() =>
                                        // setIsPkpuMenuOpen(!isPkpuMenuOpen)
                                        navigate(
                                          `/verifikasi-kreditor/${data.lawsuit_id}`
                                        )
                                      }
                                      aria-haspopup="true"
                                    >
                                      <span className="">
                                        Verifikasi Kreditor
                                      </span>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="my-2 text-purple-900 inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800  focus:outline-none"
                                      onClick={() =>
                                        // setIsPkpuMenuOpen(!isPkpuMenuOpen)
                                        navigate(`/tagihan/${data.lawsuit_id}`)
                                      }
                                      aria-haspopup="true"
                                    >
                                      <span className="">Daftar Piutang</span>
                                    </button>
                                  </li>

                                  <li>
                                    <button
                                      className="my-2 text-purple-900 inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800  focus:outline-none"
                                      onClick={() =>
                                        // setIsPkpuMenuOpen(!isPkpuMenuOpen)
                                        // navigate(`/tagihan/${data.lawsuit_id}`)
                                        exportData(data.lawsuit_id)
                                      }
                                      aria-haspopup="true"
                                    >
                                      <span className="">Export data</span>
                                    </button>
                                  </li>
                                </ul>
                              )}
                            </ul>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </li>
              </ul>
            </div>
          )}
        </li>
      </ul>
      {/* <div className="px-6 my-6">
        <button className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
          Create account
          <span className="ml-2" aria-hidden="true">
            +
          </span>
        </button>
      </div> */}
    </div>
  );
};

export default ContentSidebar;

import {
  Checkbox,
  Form,
  Image,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { useState } from "react";
import compressImage from "../../utils/CompressImage";
import { PlusOutlined } from "@ant-design/icons";

const ModalEditCreditor = (properties) => {
  console.log(properties.dataModalEdit);
  const [form] = Form.useForm();
  const prefixSelector = (
    <Form.Item name="prefix" noStyle initialValue={"+62"}>
      <Select style={{ width: 70 }}>
        <Select.Option value="62">+62</Select.Option>
      </Select>
    </Form.Item>
  );
  useEffect(() => {
    if (properties.dataModalEdit) {
      form.setFieldsValue({
        tipeKreditor: properties.dataModalEdit.creditor_type,
        nama: properties.dataModalEdit.name,
        alamat: properties.dataModalEdit.address,
        phone: properties.dataModalEdit.phone_number,
        email: properties.dataModalEdit.email,
        ktp: properties.dataModalEdit.phone_number,
      });
    }
  }, [properties.dataModalEdit, form]);
  const ID_REGEX = /^\d{16}$/;
  const [fileListKtp, setFileListKtp] = useState(
    properties.dataModalEdit &&
      properties.dataModalEdit.hasOwnProperty("fotoKtp")
      ? [properties.dataModalEdit.fotoKtp]
      : []
  );
  const [fileListAkta, setFileListAkta] = useState(
    properties.dataModalEdit && properties.dataModalEdit.hasOwnProperty("akta")
      ? [properties.dataModalEdit.akta]
      : []
  );
  const [fileListAnggaran, setFileListAnggaran] = useState(
    properties.dataModalEdit &&
      properties.dataModalEdit.hasOwnProperty("anggaran")
      ? [properties.dataModalEdit.anggaran]
      : []
  );
  const [fileListLainnya, setFileListLainnya] = useState(
    properties.dataModalEdit &&
      properties.dataModalEdit.hasOwnProperty("lainnya")
      ? [properties.dataModalEdit.lainnya]
      : []
  );
  const [fileListSK, setFileListSK] = useState(
    properties.dataModalEdit && properties.dataModalEdit.hasOwnProperty("sk")
      ? [properties.dataModalEdit.sk]
      : []
  );

  const [akta, setAkta] = useState(null);
  const [sk, setSK] = useState(null);
  const [anggaran, setAnggaran] = useState(null);
  const [lainnya, setLainnya] = useState(null);
  const [lawsuit, setLawsuit] = useState();
  const [creditorType, setCreditorType] = useState(
    properties.dataModalEdit &&
      properties.dataModalEdit.hasOwnProperty("values")
      ? properties.dataModalEdit.values.tipeKreditor
      : "individual"
  );
  const [attorney, setAttorney] = useState(
    properties.dataModalEdit &&
      properties.dataModalEdit.values &&
      properties.dataModalEdit.values.hasOwnProperty("attorneyName")
      ? true
      : false
  );
  const onChanged = async (info) => {
    const isImage = info.file.type.startsWith("image/");

    // If the file type is not an image, reject it
    if (!isImage) {
      message.error("You can only upload image files!");
      return false;
    }
    if (info.file.status !== "removed") {
      properties.setDataPersonal((prevData) => ({
        ...prevData,
        fotoKtp: info.file,
      }));
    }
  };
  const onChangedAkta = async (info) => {
    const isImage = info.file.type.startsWith("image/");

    // If the file type is not an image, reject it
    if (!isImage) {
      message.error("You can only upload image files!");
      return false;
    }
    if (info.file.status !== "removed") {
      properties.setDataPersonal((prevData) => ({
        ...prevData,
        akta: info.file,
      }));
    }
  };
  const onChangedSK = async (info) => {
    const isImage = info.file.type.startsWith("image/");

    // If the file type is not an image, reject it
    if (!isImage) {
      message.error("You can only upload image files!");
      return false;
    }
    if (info.file.status !== "removed") {
      properties.setDataPersonal((prevData) => ({
        ...prevData,
        sk: info.file,
      }));
    }
  };
  const onChangedAnggaran = async (info) => {
    const isImage = info.file.type.startsWith("image/");

    // If the file type is not an image, reject it
    if (!isImage) {
      message.error("You can only upload image files!");
      return false;
    }
    if (info.file.status !== "removed") {
      properties.setDataPersonal((prevData) => ({
        ...prevData,
        anggaran: info.file,
      }));
    }
  };
  const onChangedLainnya = async (info) => {
    const isImage = info.file.type.startsWith("image/");

    // If the file type is not an image, reject it
    if (!isImage) {
      message.error("You can only upload image files!");
      return false;
    }
    if (info.file.status !== "removed") {
      properties.setDataPersonal((prevData) => ({
        ...prevData,
        lainnya: info.file,
      }));
    }
  };
  const props = {
    onRemove: (file) => {
      const index = fileListKtp.indexOf(file);
      const newFileList = fileListKtp.slice();
      newFileList.splice(index, 1);
      setFileListKtp(newFileList);
    },
    beforeUpload: async (file) => {
      const isImage = file.type.startsWith("image/");

      // If the file type is not an image, reject it
      if (!isImage) {
        // message.error("You can only upload image files!");
        // return true;
      } else {
        setFileListKtp([...fileListKtp, await compressImage(file)]);
        properties.handleFileKtp(file);
      }
      return false;
    },
    fileListKtp,
  };
  const propsSK = {
    onRemove: (file) => {
      const index = fileListSK.indexOf(file);
      const newFileList = fileListSK.slice();
      newFileList.splice(index, 1);
      setFileListSK(newFileList);
    },
    beforeUpload: async (file) => {
      // TODO: perbedaan tidak ada handle file
      const isImage = file.type.startsWith("image/");

      // If the file type is not an image, reject it
      if (!isImage) {
        // message.error("You can only upload image files!");
        // return true;
      } else {
        setFileListSK([...fileListSK, await compressImage(file)]);
      }
      return false;
    },
    fileListSK,
  };
  const propsAkta = {
    onRemove: (file) => {
      const index = fileListAkta.indexOf(file);
      const newFileList = fileListAkta.slice();
      newFileList.splice(index, 1);
      setFileListAkta(newFileList);
    },
    beforeUpload: async (file) => {
      // TODO: perbedaan tidak ada handle file
      const isImage = file.type.startsWith("image/");

      // If the file type is not an image, reject it
      if (!isImage) {
        // message.error("You can only upload image files!");
        // return true;
      } else {
        setFileListAkta([...fileListAkta, await compressImage(file)]);
      }
      return false;
    },
    fileListAkta,
  };

  const propsAnggaran = {
    onRemove: (file) => {
      const index = fileListAnggaran.indexOf(file);
      const newFileList = fileListAnggaran.slice();
      newFileList.splice(index, 1);
      setFileListAnggaran(newFileList);
    },
    beforeUpload: async (file) => {
      // TODO: perbedaan tidak ada handle file
      const isImage = file.type.startsWith("image/");

      // If the file type is not an image, reject it
      if (!isImage) {
        // message.error("You can only upload image files!");
        // return true;
      } else {
        setFileListAnggaran([...fileListAnggaran, await compressImage(file)]);
      }
      return false;
    },
    fileListAnggaran,
  };

  const propsLainnya = {
    onRemove: (file) => {
      const index = fileListLainnya.indexOf(file);
      const newFileList = fileListLainnya.slice();
      newFileList.splice(index, 1);
      setFileListLainnya(newFileList);
    },
    beforeUpload: async (file) => {
      // TODO: perbedaan tidak ada handle file
      const isImage = file.type.startsWith("image/");

      // If the file type is not an image, reject it
      if (!isImage) {
        // message.error("You can only upload image files!");
        // return true;
      } else {
        setFileListLainnya([...fileListLainnya, await compressImage(file)]);
      }
      return false;
    },
    fileListLainnya,
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handlePerorangan = (value) => {
    setCreditorType(value);

    // setPerorangan(!perorangan);
  };
  const handleLawsuit = (value) => {
    setLawsuit(value);

    // setPerorangan(!perorangan);
  };

  const handleAttorney = (value) => {
    setAttorney(value.target.checked);
    // setPerorangan(!perorangan);
  };

  async function onFinish(values) {
    properties.setDataPersonal((prevData) => ({
      ...prevData,
      values,
    }));
    // properties.setDataPersonal(values);
    properties.setCurrent(properties.current + 1);
  }
  console.log("dataprops", props.dataEditTagihan);

  useEffect(() => {
    form.setFieldsValue(props.dataEditTagihan);
  }, [props.dataEditTagihan]);
  console.log("modal edit", props.modalEdit);
  return (
    <Modal
      open={properties.modalEdit}
      onCancel={() => properties.setModalEdit(false)}
    >
      <Form form={form} layout="vertical" name="nest-messages">
        <div className="flex flex-col w-full overflow-y-auto md:flex-row ">
          {/* KIRI================== */}
          <div className="flex  justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <Form.Item
                label="Tipe Kreditor"
                name="tipeKreditor"
                initialValue={creditorType}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select onChange={handlePerorangan}>
                  <Select.Option value="individual">Perorangan</Select.Option>
                  <Select.Option value="company">Badan Usaha</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={"nama"}
                label="Nama"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Alamat"
                name={"alamat"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name={"email"}
                label="Email"
                rules={[
                  {
                    type: "email",
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          {/* KANAN ==================== */}
          <div className="flex  justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              {creditorType === "individual" && (
                <>
                  <Form.Item
                    name="ktp"
                    label="NIK KTP"
                    rules={[
                      {
                        required: true,
                        message: "Masukkan Nomor KTP",
                      },
                      {
                        pattern: ID_REGEX,
                        message: "Format KTP Tidak Sesuai",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  {properties.dataModalEdit &&
                  properties.dataModalEdit.hasOwnProperty("fotoKtp") ? (
                    <div>
                      <h2 className="flex justify-between mb-3">
                        Foto Ktp:
                        <span>
                          <button
                            onClick={() => {
                              const newData = { ...properties.dataModalEdit };
                              delete newData.fotoKtp;
                              setFileListKtp([]);
                              properties.setDataPersonal(newData);
                            }}
                            className="text-red-700"
                          >
                            Delete
                          </button>
                        </span>
                      </h2>
                      <Image
                        height={100}
                        width={100}
                        src={URL.createObjectURL(
                          properties.dataModalEdit.fotoKtp
                        )}
                      ></Image>
                    </div>
                  ) : (
                    <Form.Item label="Foto Copy KTP" name={"photocopyKtp"}>
                      <Upload
                        {...props}
                        accept="image/*"
                        onChange={onChanged}
                        listType="picture-card"
                        onPreview={handlePreview}
                      >
                        {fileListKtp.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Select File
                            </div>
                          </div>
                        )}
                      </Upload>

                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  )}
                </>
              )}
              {creditorType === "company" && (
                <>
                  {/* TODO:Belum selesai */}
                  {properties.dataModalEdit &&
                  properties.dataModalEdit.hasOwnProperty("akta") ? (
                    <div>
                      <h2 className="flex justify-between mb-3">
                        Akta:
                        <span>
                          <button
                            onClick={() => {
                              const newData = { ...properties.dataModalEdit };
                              delete newData.akta;
                              // untuk memunculkan kembali plusoutlined
                              setFileListAkta([]);
                              properties.setDataPersonal(newData);
                            }}
                            className="text-red-700"
                          >
                            Delete
                          </button>
                        </span>
                      </h2>
                      <Image
                        height={100}
                        width={100}
                        src={URL.createObjectURL(properties.dataModalEdit.akta)}
                      ></Image>
                    </div>
                  ) : (
                    <>
                      <Form.Item label="Foto Copy Akta">
                        <Upload
                          {...propsAkta}
                          accept="image/*"
                          onChange={onChangedAkta}
                          listType="picture-card"
                          onPreview={handlePreview}
                        >
                          {fileListAkta.length >= 1 ? null : (
                            <div>
                              <PlusOutlined />
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                Select File
                              </div>
                            </div>
                          )}
                        </Upload>
                        <Modal
                          open={previewOpen}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{
                              width: "100%",
                            }}
                            src={previewImage}
                          />
                        </Modal>
                      </Form.Item>
                    </>
                  )}
                  {properties.dataModalEdit &&
                  properties.dataModalEdit.hasOwnProperty("anggaran") ? (
                    <div>
                      <h2 className="flex justify-between mb-3">
                        Anggaran:
                        <span>
                          <button
                            onClick={() => {
                              const newData = { ...properties.dataModalEdit };
                              delete newData.anggaran;
                              // untuk memunculkan kembali plusoutlined
                              setFileListAnggaran([]);
                              properties.setDataPersonal(newData);
                            }}
                            className="text-red-700"
                          >
                            Delete
                          </button>
                        </span>
                      </h2>
                      <Image
                        height={100}
                        width={100}
                        src={URL.createObjectURL(
                          properties.dataModalEdit.anggaran
                        )}
                      ></Image>
                    </div>
                  ) : (
                    <Form.Item label="Foto Copy Angaran Dasar">
                      <Upload
                        {...propsAnggaran}
                        accept="image/*"
                        onChange={onChangedAnggaran}
                        listType="picture-card"
                        onPreview={handlePreview}
                      >
                        {fileListAnggaran.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Select File
                            </div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  )}
                  {properties.dataModalEdit &&
                  properties.dataModalEdit.hasOwnProperty("lainnya") ? (
                    <div>
                      <h2 className="flex justify-between mb-3">
                        Dokumen lainnya:
                        <span>
                          <button
                            onClick={() => {
                              const newData = { ...properties.dataModalEdit };
                              delete newData.lainnya;
                              // untuk memunculkan kembali plusoutlined
                              setFileListLainnya([]);
                              properties.setDataPersonal(newData);
                            }}
                            className="text-red-700"
                          >
                            Delete
                          </button>
                        </span>
                      </h2>
                      <Image
                        height={100}
                        width={100}
                        src={URL.createObjectURL(
                          properties.dataModalEdit.lainnya
                        )}
                      ></Image>
                    </div>
                  ) : (
                    <Form.Item label="Dokumen Lainnya">
                      <Upload
                        accept="image/*"
                        {...propsLainnya}
                        onChange={onChangedLainnya}
                        listType="picture-card"
                        onPreview={handlePreview}
                      >
                        {fileListLainnya.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Select File
                            </div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  )}
                </>
              )}

              <Form.Item
                name="disabled"
                valuePropName="checked"
                className="mt-7"
              >
                <Tooltip title="Checklist jika menggunakan kuasa hukum">
                  <Checkbox
                    onChange={handleAttorney}
                    defaultChecked={
                      properties.dataModalEdit &&
                      properties.dataModalEdit.values &&
                      properties.dataModalEdit.values.hasOwnProperty(
                        "attorneyName"
                      )
                        ? true
                        : false
                    }
                  >
                    Kuasa Hukum
                  </Checkbox>
                </Tooltip>
              </Form.Item>
              {attorney && (
                <>
                  <Form.Item
                    name={"attorneyName"}
                    label="Nama Kuasa Hukum"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Alamat"
                    name={"alamatAttorney"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                  <Form.Item
                    name="phone_attorney"
                    label="Nomor Telepon Kuasa Hukum"
                    wrapperCol={true}
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                    className={`${attorney ? "visible" : "hidden"}`}
                  >
                    <Input
                      addonBefore={prefixSelector}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  {properties.dataModalEdit &&
                  properties.dataModalEdit.hasOwnProperty("sk") ? (
                    <div>
                      <h2 className="flex justify-between mb-3">
                        Surat Kuasa:
                        <span>
                          <button
                            onClick={() => {
                              const newData = { ...properties.dataModalEdit };
                              delete newData.sk;
                              // untuk memunculkan kembali plusoutlined
                              setFileListSK([]);
                              properties.setDataPersonal(newData);
                            }}
                            className="text-red-700"
                          >
                            Delete
                          </button>
                        </span>
                      </h2>
                      <Image
                        height={100}
                        width={100}
                        src={URL.createObjectURL(properties.dataModalEdit.sk)}
                      ></Image>
                    </div>
                  ) : (
                    <Form.Item
                      label="Surat Kuasa"
                      name={"surat kuasa"}
                      rules={[
                        {
                          required: fileListSK.length >= 1 ? false : true,
                        },
                      ]}
                      className={`${
                        attorney ? "fade-in" : "hidden"
                      } transition-all duration-1000`}
                    >
                      <Upload
                        accept="image/*"
                        onChange={onChangedSK}
                        {...propsSK}
                        listType="picture-card"
                        onPreview={handlePreview}
                      >
                        {fileListSK.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Select File
                            </div>
                          </div>
                        )}
                      </Upload>
                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  )}
                </>
              )}
              {/* <Form.Item
              wrapperCol={{
                offset: 8,
              }}
            >
              <button className="ant-btn ant-btn-primary mt-5 bg-purple-600 hover:bg-purple-700 py-2 px-5 rounded-md text-white">
                Submit
              </button>
            </Form.Item> */}
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditCreditor;

import axios from "axios";
import API_ENDPOINT from "../Config/API_ENDPOINT";

class RegisterService {
  constructor() {
    this._axios = axios;
    this._endpoint = API_ENDPOINT;
  }

  async postRegisterCreditors(payload) {
    try {
      const response = await this._axios({
        method: "POST",
        url: `${this._endpoint.CREDITOR}`,
        headers: {},
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async postRegisterInvoice(payload) {
    try {
      const response = await this._axios({
        method: "POST",
        url: `${this._endpoint.INVOICE}`,
        headers: {},
        data: payload,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async generatePDF(creditorId) {
    try {
      const response = await this._axios({
        method: "GET",
        url: `${this._endpoint.CREDITOR}/pdf/${creditorId}`,
        headers: {},
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export default RegisterService;
